import React, { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'
import DataForm from '../DataForm'
import Dialog from '../Dialog'
import getStyles from './getStyles'
import AccessIcon from '../AccessIcon'

class EditDialog extends PureComponent {
  constructor(props) {
    super(props)

    const { data } = props

    this.state = {
      data,
      open: !!data,
    }
  }

  handleChange = field => {
    const { data } = this.state

    this.setState({
      data: {
        ...data,
        ...field,
      },
    })
  }

  handleConfirm = () => {
    const { onConfirm } = this.props

    onConfirm(this.state.data)
  }

  componentWillReceiveProps(props) {
    const { data } = props

    this.setState({
      data,
      open: !!data,
    })
  }

  render() {
    const { titleField, loading, onClose } = this.props
    const { data, open } = this.state

    const access = this.props.fields.find(f => f.type === 'access')
    const fields = access
      ? this.props.fields.filter(f => f.type !== 'access')
      : this.props.fields

    return (
      <Dialog
        title={open ? 'Edit ' + (data[titleField] || '') : ''}
        maxWidth="sm"
        {...{ open, onClose, onConfirm: this.handleConfirm }}
      >
        {!!(access && data) && (
          <AccessIcon value={data[access.name]} type="dialog" />
        )}

        <DataForm
          fields={fields}
          data={data}
          disabled={loading}
          onChange={this.handleChange}
        />
      </Dialog>
    )
  }
}

export default withStyles(getStyles)(EditDialog)

import React, { PureComponent } from 'react'
import { compose } from 'ramda'
import { navigate } from '@reach/router'
import { withStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import { connect, actions } from '../../store'
import withMatchingItem from '../../lib/withMatchingItem'
import withRouteMatch from '../../lib/withRouteMatch'
import { getError, isLoading } from '../../store/selectors'
import Page from '../Page'
import EditDialog from '../EditDialog'
import ViewDialog from '../ViewDialog'
import DeleteDialog from '../DeleteDialog'
import DataTable, { DeleteButton, EditButton, ViewButton } from '../DataTable'
import AddDialog from '../AddDialog'
import ActionButton from './ActionButton'
import getStyles from './getStyles'

class CrudPage extends PureComponent {
  constructor(props) {
    super(props)

    const { idField = 'id', getItem } = props

    this.RoutedAddDialog = withRouteMatch('new')(AddDialog)
    this.RoutedEditDialog = withMatchingItem(
      `edit/:${idField}`,
      getItem
    )(EditDialog)
    this.RoutedViewDialog = withMatchingItem(
      `view/:${idField}`,
      getItem
    )(ViewDialog)
    this.RoutedDeleteDialog = withMatchingItem(
      `delete/:${idField}`,
      getItem
    )(DeleteDialog)
  }

  hasError = () => !!this.props.error

  handleCloseDialog = () => {
    navigate(this.props.path.replace(/(\/\*$)/, ''))
  }

  handleConfirmAdd = async data => {
    await actions.createItem(this.props.endpoint, data)

    if (!this.hasError()) {
      this.handleCloseDialog()
    }
  }

  handleConfirmEdit = async data => {
    await actions.updateItem(this.props.endpoint, data)

    if (!this.hasError()) {
      this.handleCloseDialog()
      return
    }
  }

  handleConfirmDelete = async data => {
    this.handleCloseDialog()

    await actions.deleteItem(this.props.endpoint, data)
  }

  async componentDidMount() {
    await actions.fetchItems(this.props.endpoint)

    // Fetch dependencies if they're not in the store already
    if (this.props.dependencies) {
      const tasks = this.props.dependencies
        .filter(
          endpoint =>
            !this.props.state[endpoint] || !this.props.state[endpoint].length
        )
        .map(async endpoint => actions.fetchItems(endpoint))

      await Promise.all(tasks)
    }
  }

  render() {
    const {
      children,
      classes,
      actions,
      objectActions = [ViewButton, EditButton, DeleteButton],
      bulkActions = [],
      fields,
      data,
      initialData,
      titleField,
      title,
      description,
      loading,
      getItem,
      rowsPerPageOptions,
    } = this.props
    const {
      RoutedAddDialog,
      RoutedViewDialog,
      RoutedEditDialog,
      RoutedDeleteDialog,
    } = this

    return (
      <Page title={title} description={description} className={classes.root}>
        <DataTable
          fields={fields}
          data={data}
          actions={objectActions}
          bulkActions={bulkActions}
          rowsPerPageOptions={rowsPerPageOptions}
        />

        <div className={classes.footer}>
          {actions ? (
            actions.map((Action, i) => <Action key={i} classes={classes} />)
          ) : (
            <ActionButton
              className={classes.footerButton}
              title="Add new"
              to="new"
              icon={AddIcon}
            />
          )}
        </div>

        {children}

        <RoutedAddDialog
          fields={fields}
          initialData={initialData}
          loading={loading}
          onClose={this.handleCloseDialog}
          onConfirm={this.handleConfirmAdd}
        />
        <RoutedViewDialog
          fields={fields}
          titleField={titleField}
          selector={getItem}
          onClose={this.handleCloseDialog}
          onConfirm={this.handleCloseDialog}
        />
        <RoutedEditDialog
          fields={fields}
          titleField={titleField}
          loading={loading}
          onClose={this.handleCloseDialog}
          onConfirm={this.handleConfirmEdit}
        />
        <RoutedDeleteDialog
          titleField={titleField}
          onClose={this.handleCloseDialog}
          onConfirm={this.handleConfirmDelete}
        />
      </Page>
    )
  }
}

const resolveFieldValues = state => ({ values, ...field }) => ({
  ...field,
  ...(values && {
    values: typeof values === 'function' ? values(state) : values,
  }),
})

const mapStateToProps = (state, { fields, getItems }) => ({
  data: getItems(state),
  fields: fields.map(resolveFieldValues(state)),
  error: getError(state),
  loading: isLoading(state),
  state,
})

export default compose(
  connect(mapStateToProps),
  withStyles(getStyles)
)(CrudPage)

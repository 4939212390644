import React, { PureComponent } from 'react'
import { withStyles } from '@material-ui/core'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import SendIcon from '@material-ui/icons/Send'
import { navigate } from '@reach/router'
import { compose } from 'ramda'
import withRouteMatch from '../../lib/withRouteMatch'
import { actions, connect } from '../../store'
import { getInvitations, getInvitationByEmail } from '../../store/selectors'
import {
  ActionButton as ObjectActionButton,
  DeleteButton,
  EditButton,
  ViewButton,
} from '../DataTable'
import CrudPage, { ActionButton } from '../CrudPage'
import UploadDialog from './UploadDialog'
import SendDialog from './SendDialog'
import * as models from '../../models'
import getStyles from './getStyles'

const endpoint = 'api/invitations'

const RoutedUploadDialog = withRouteMatch('upload')(UploadDialog)

const RoutedSendDialog = withRouteMatch('send')(SendDialog)

const UploadButton = ({ classes }) => <ActionButton
  className={classes.footerButton}
  title='Upload list'
  to='upload'
  icon={CloudUploadIcon}
/>

const SampleCSVLink = ({ classes }) =>
  <a className={classes.footerLink} href='/assets/sample-captain-invitations.csv'>
    Download sample CSV
  </a>

const getEmail = ({ email }) => email

class Invitations extends PureComponent {
  state = {
    bulkSelection: null,
  }

  handleCloseDialog = () => {
    this.setState({ bulkSelection: null })
    navigate(this.props.path.replace(/(\/\*$)/, ''))
  }

  handleUpload = async files => {
    this.handleCloseDialog()

    await actions.uploadInvitations(files[0])
    await actions.fetchItems(endpoint)
  }

  handleSendInvitationsClick = bulkSelection => {
    this.setState({ bulkSelection })
  }

  handleSendInvitations = async invitations => {
    this.handleCloseDialog()

    await actions.sendInvitations(invitations.map(getEmail))
    await actions.fetchItems(endpoint)
  }

  render() {
    const SendBulkInvitationButton = ({ selected, ...props }) => <ObjectActionButton
      title='Send bulk invitation'
      to={'send'}
      icon={SendIcon}
      color='default'
      onClick={() => this.handleSendInvitationsClick(selected)}
      {...props}
    />

    const SendInvitationButton = ({ id, ...props }) => <ObjectActionButton
      title='Send invitation'
      to={'send'}
      icon={SendIcon}
      onClick={() => this.handleSendInvitationsClick([id])}
      {...props}
    />

    const { classes, path, getInvitation } = this.props
    const { bulkSelection } = this.state

    return (
      <CrudPage {...{
        endpoint,
        dependencies: ['api/teams'],
        actions: [SampleCSVLink, UploadButton],
        objectActions: [
          ViewButton,
          EditButton,
          DeleteButton,
          SendInvitationButton,
        ],
        bulkActions: [SendBulkInvitationButton],
        fields: models.invitation,
        idField: 'email',
        titleField: 'email',
        title: 'Invitations',
        path,
        getItem: getInvitationByEmail,
        getItems: getInvitations,
        rowsPerPageOptions: [15, 25, 50, 100, 500],
      }}>
        <RoutedUploadDialog
          classes={classes}
          onClose={this.handleCloseDialog}
          onUpload={this.handleUpload}
        />
        <RoutedSendDialog
          classes={classes}
          data={bulkSelection && bulkSelection.map(getInvitation)}
          onClose={this.handleCloseDialog}
          onConfirm={this.handleSendInvitations}
        />
      </CrudPage>
    )
  }
}

const mapStateToProps = state => ({
  getInvitation: email => getInvitationByEmail(state, { email }),
})

export default compose(
  connect(mapStateToProps),
  withStyles(getStyles)
)(Invitations)

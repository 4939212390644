import React from 'react'
import { withStyles } from '@material-ui/core'
import { getFreeSeries, getFreeSeriesById } from '../../../store/selectors'
import CrudPage from '../../CrudPage'
import * as models from '../../../models'
import getStyles from './getStyles'

const Venues = ({ path }) => (
  <CrudPage
    {...{
      key: path,
      endpoint: 'series',
      dependencies: ['venues', 'regions'],
      fields: models.freeSeries,
      initialData: {
        name: '',
        venueId: '',
        regionId: '',
        maxTeamCount: '',
        isPublic: '',
      },
      titleField: 'name',
      title: 'Series - Free Game',
      path,
      getItem: getFreeSeriesById,
      getItems: getFreeSeries,
    }}
  />
)

export default withStyles(getStyles)(Venues)

import React from 'react'
import { withStyles } from '@material-ui/core'
import { getTeams, getTeamById } from '../../store/selectors'
import CrudPage from '../CrudPage'
import * as models from '../../models'
import getStyles from './getStyles'

const Teams = ({ path }) =>
  <CrudPage {...{
    endpoint: 'api/teams',
    dependencies: ['api/regions'],
    fields: models.team,
    initialData: {
      name: '',
      imageUrl: '',
    },
    titleField: 'name',
    title: 'Teams',
    path,
    getItem: getTeamById,
    getItems: getTeams,
  }} />

export default withStyles(getStyles)(Teams)

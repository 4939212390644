import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'ramda'
import { withStyles } from '@material-ui/core/styles'
import BaseAppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import MenuIcon from '@material-ui/icons/Menu'
import AccountCircle from '@material-ui/icons/AccountCircle'
import { actions, connect } from '../../store'
import * as selectors from '../../store/selectors'
import AppMenu from '../Menu'
import Loader from '../Loader'
import getStyles from './getStyles'

class AppBar extends Component {
  state = {
    menuOpen: false,
  }

  toggleDrawer = menuOpen => () => {
    this.setState({
      menuOpen,
    })
  }

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  handleLogout = () => {
    this.handleClose()
    actions.logout()
  }

  render() {
    const { classes, user } = this.props
    const { anchorEl, menuOpen } = this.state

    return (
      <>
        <BaseAppBar position="sticky">
          <Toolbar>
            {user && (
              <IconButton
                className={classes.menuButton}
                color="inherit"
                aria-label="Menu"
                onClick={this.toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
            )}

            <Typography variant="h6" color="inherit" className={classes.grow}>
              S60 - CMS
            </Typography>

            {user && (
              <React.Fragment>
                <IconButton
                  aria-owns={!!anchorEl ? 'menu-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={!!anchorEl}
                  onClose={this.handleClose}
                >
                  <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
                </Menu>
              </React.Fragment>
            )}
          </Toolbar>

          <Loader />
        </BaseAppBar>

        {user && (
          <SwipeableDrawer
            anchor="left"
            open={menuOpen}
            onClose={this.toggleDrawer(false)}
            onOpen={this.toggleDrawer(true)}
          >
            <div
              tabIndex={0}
              role="button"
              onClick={this.toggleDrawer(false)}
              onKeyDown={this.toggleDrawer(false)}
            >
              <AppMenu />
            </div>
          </SwipeableDrawer>
        )}
      </>
    )
  }
}

AppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object,
}

const mapStateToProps = state => ({
  user: selectors.getUser(state),
})

export default compose(connect(mapStateToProps), withStyles(getStyles))(AppBar)

import React from 'react'
import { withStyles } from '@material-ui/core'
import { getRewards, getRewardById } from '../../store/selectors'
import CrudPage from '../CrudPage'
import * as models from '../../models'
import getStyles from './getStyles'

const Rewards = ({ path }) =>
  <CrudPage {...{
    endpoint: 'api/rewards',
    fields: models.reward,
    initialData: {
      name: '',
      lead: '',
      coupon: '',
      url: '',
    },
    titleField: 'name',
    title: 'Rewards',
    path,
    getItem: getRewardById,
    getItems: getRewards,
  }} />

export default withStyles(getStyles)(Rewards)

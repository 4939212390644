import React from 'react'
import { withStyles } from '@material-ui/core'
import { getFreeAccounts, getFreeAccountById } from '../../../store/selectors'
import CrudPage from '../../CrudPage'
import * as models from '../../../models'
import getStyles from './getStyles'

const Accounts = ({ path }) => (
  <CrudPage
    {...{
      endpoint: 'accounts',
      actions: [],
      fields: models.freeAccount,
      titleField: 'name',
      title: 'Accounts - Free Games',
      path,
      getItem: getFreeAccountById,
      getItems: getFreeAccounts,
      rowsPerPageOptions: [15, 25, 50, 100, 500],
    }}
  />
)

export default withStyles(getStyles)(Accounts)

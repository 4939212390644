export default ({ mixins, spacing }) => ({
  root: mixins.gutters({
    flex: '1 1 auto',
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: spacing.unit * 3,
    marginLeft: spacing.unit * 2,
    marginRight: spacing.unit * 2,
  }),
  description: {
    marginTop: spacing.unit,
  },
})

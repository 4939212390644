import { navigate as changeUrl } from '@reach/router'
import { filter, pick, pathOr } from 'ramda'
import toCamelCase from 'camelcase'
import { signIn, signOut, fetchWithAuth, waitForAuth } from '../firebase'

const waitForUser = async () => {
  const user = await waitForAuth()

  return user && pick(['email', 'displayName', 'photoUrl'], user)
}

export const initializeApp = async () => {
  const state = {
    initialized: true,
  }

  state.user = await waitForUser()

  if (state.user) {
    try {
      setupUserForZenDeskChat(state.user)
    } catch (error) {
      state.error = error
      state.loading = false
    }
  }

  return state
}

export const showSnackMessage = (_s, _a, snackMessage, delay = 3000) => ({
  snackMessage,
})

export const clearSnackMessage = () => ({
  snackMessage: null,
})

export const clearError = () => ({
  error: null,
})

export const startLogin = () => ({
  loading: true,
})

export const login = async (_, actions, { email, password, remember }) => {
  await actions.startLogin()

  const state = {}

  try {
    await signIn({ email, password, remember })

    const user = await waitForUser()

    state.user = user
    state.error = null
  } catch (error) {
    state.error = error
  }

  return {
    ...state,
    loading: false,
  }
}

export const logout = async (_s, actions) => {
  await signOut()
  await actions.navigate('/logout')

  return {
    user: null,
  }
}

export const navigate = (_s, _a, path) => changeUrl(path)

export const startFetchItems = () => ({
  loading: true,
})

export const fetchItems = async (_, actions, endpoint) => {
  await actions.startFetchItems(endpoint)

  const name = toCamelCase(endpoint)
  const state = {}

  try {
    state[name] = await fetchWithAuth(`/${endpoint}`)
    state.error = null
  } catch (error) {
    state[name] = []
    state.error = error
  }

  return {
    ...state,
    loading: false,
  }
}

const getIdField = name => {
  const fieldConfig = {
    // TODO refactor !
    invitations: {
      id: 'email',
    },
  }

  return pathOr('id', [name, 'id'], fieldConfig)
}

export const startCreateItem = () => ({
  loading: true,
})

export const createItem = async (store, actions, endpoint, data) => {
  await actions.startCreateItem(endpoint, data)

  const name = toCamelCase(endpoint)
  const state = {}

  try {
    const newData = await fetchWithAuth(`/${endpoint}`, {
      method: 'POST',
      body: JSON.stringify(data),
    })
    await actions.showSnackMessage('Create success')

    state[name] = [...store[name], newData]
    state.error = null
  } catch (error) {
    state.error = error
  }

  return {
    ...state,
    loading: false,
  }
}

export const startUpdateItem = () => ({
  loading: true,
})

export const updateItem = async (store, actions, endpoint, data, silent) => {
  await actions.startUpdateItem(endpoint, data)

  const name = toCamelCase(endpoint)
  const idField = getIdField(name)
  const { [idField]: id } = data
  const state = {}

  try {
    const updatedData = await fetchWithAuth(`/${endpoint}/${id}`, {
      method: 'PATCH',
      body: JSON.stringify(data),
    })

    !silent && (await actions.showSnackMessage('Update success'))

    state[name] = store[name].map(item =>
      item[idField] === id
        ? {
            ...item,
            ...(updatedData || data),
          }
        : item
    )
    state.error = null
  } catch (error) {
    state.error = error
  }

  return {
    ...state,
    loading: false,
  }
}

export const startDeleteItem = () => ({
  loading: true,
})

export const deleteItem = async (store, actions, endpoint, data) => {
  await actions.startDeleteItem(endpoint, data)

  const name = toCamelCase(endpoint)
  const idField = getIdField(name)
  const { [idField]: id } = data
  const state = {}

  try {
    await fetchWithAuth(`/${endpoint}/${id}`, {
      method: 'DELETE',
    })
    await actions.showSnackMessage('Delete success')

    state[name] = filter(({ [idField]: _id }) => _id !== id, store[name])
    state.error = null
  } catch (error) {
    state.error = error
  }

  return {
    ...state,
    loading: false,
  }
}

export const startUploadInvitations = () => ({
  loading: true,
})

export const uploadInvitations = async (_, actions, file) => {
  await actions.startUploadInvitations(file)

  const state = {}
  const data = new FormData()

  data.append('list', file)

  try {
    const response = await fetchWithAuth(`/invitations/upload-list`, {
      method: 'POST',
      body: data,
      headers: {
        'Content-Type': null,
      },
    })
    await actions.showSnackMessage(
      `${response.length} invitation(s) have been uploaded`
    )

    state.error = null
  } catch (error) {
    state.error = error
  }

  return {
    ...state,
    loading: false,
  }
}

export const startSendInvitations = () => ({
  loading: true,
})

export const sendInvitations = async (store, actions, data) => {
  await actions.startSendInvitations(data)

  const state = {}

  try {
    const results = await fetchWithAuth(`/invitations/send`, {
      method: 'POST',
      body: JSON.stringify(data),
    })
    const successCount = results.reduce(
      (count, { success }) => +success + count,
      0
    )

    await actions.showSnackMessage(`${successCount} invitation(s) was sent`)

    state.error = null
  } catch (error) {
    state.error = error
  }

  return {
    ...state,
    loading: false,
  }
}

export const startSendNotifications = () => ({
  loading: true,
})

export const sendNotifications = async (store, actions, data) => {
  await actions.startSendNotifications(data)

  const state = {}

  try {
    await fetchWithAuth(`/notifications/send`, {
      method: 'POST',
      body: JSON.stringify(data),
    })

    await actions.showSnackMessage(`Notifications was sent`)

    state.error = null
  } catch (error) {
    state.error = error
  }

  return {
    ...state,
    loading: false,
  }
}

export const startSimulateMatch = () => ({
  loading: true,
})

export const simulateMatch = async (store, actions, stage, data) => {
  await actions.startSimulateMatch(stage, data)

  const state = {}
  const { matchId, teamId, payload } = data

  try {
    const match = await fetchWithAuth(
      `/matches/${matchId}/simulate/${stage}/${teamId}`,
      {
        method: 'POST',
        body: JSON.stringify(payload),
      }
    )

    await actions.showSnackMessage('Update success')

    state.matches = store.matches.map(item =>
      item.id === matchId
        ? {
            ...item,
            ...match,
          }
        : item
    )
    state.error = null
  } catch (error) {
    state.error = error
  }

  return {
    ...state,
    loading: false,
  }
}

export const startUpdateMatchPlayer = () => ({
  loading: true,
})

export const updateMatchPlayer = async (store, actions, data) => {
  await actions.startUpdateMatchPlayer(data)

  const state = {}
  const { matchId, playerId, payload } = data

  try {
    const matchPlayer = await fetchWithAuth(
      `/matches/${matchId}/players/${playerId}`,
      {
        method: 'POST',
        body: JSON.stringify(payload),
      }
    )

    await actions.showSnackMessage('Update success')

    state.matches = store.matches.map(item =>
      item.id === matchId
        ? {
            ...item,
            players: item.players.map(player =>
              player.id === matchPlayer.id ? matchPlayer : player
            ),
          }
        : item
    )
    state.error = null
  } catch (error) {
    state.error = error
  }

  return {
    ...state,
    loading: false,
  }
}

const setupUserForZenDeskChat = user => {
  if (user) {
    window.Intercom('boot', {
      app_id: 'dgmlndm0',
      email: user.email,
      user_id: user.displayName,
      custom_launcher_selector: '#cms_s60',
    })
    // window.zE.identify({
    //   name: user.displayName,
    //   email: user.email,
    //   organization: 'Puzzle'
    // });
  }
}

import React from 'react'
import { connect } from '../store'
import * as selectors from '../store/selectors'
import Login from '../components/Login'


const mapStateToProps = state => ({
  login: !!selectors.getUser(state),
})

export default Component => connect(mapStateToProps)(({ login, ...props }) => {
  if (!login) {
    return <Login />
  }
  return <Component {...props} />
})

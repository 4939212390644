
import React, { Fragment, useState } from 'react'
import { compose } from 'ramda'
import { withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Typography from '@material-ui/core/Typography'
import { connect } from '../../store'
import { isLoading } from '../../store/selectors'
import Dialog from '../Dialog'
import Setup from './Setup'
import Start from './Start'
import Scoring from './Scoring'
import Finish from './Finish'
import Voting from './Voting'
import getStyles from './getStyles'

export const Context = React.createContext()

const steps = [
  {
    label: 'Setup teams',
    instructions: 'Setup instructions come here...',
    Component: Setup,
  },
  {
    label: 'Start match',
    instructions: 'Start instructions come here...',
    Component: Start,
  },
  {
    label: 'Scoring',
    instructions: 'Scoring instructions come here...',
    Component: Scoring,
  },
  {
    label: 'Finish match',
    instructions: 'Finish instructions come here...',
    Component: Finish,
  },
  {
    label: 'Voting',
    instructions: 'Voting instructions come here...',
    Component: Voting,
  },
]

export const stepStatusMap = ['setup', 'ready', 'started', 'finished']

const getStepFromStatus = status => {
  let step = stepStatusMap.indexOf(status)

  if (step > 1) {
    step++
  }

  return step
}

const canProgress = ({ match, activeStep }) => getStepFromStatus(match.status) > activeStep

const SimulateMatchDialog = ({ classes, data: match, loading, onClose }) => {
  const [activeStep, setActiveStep] = match
    ? useState(getStepFromStatus(match.status)) // eslint-disable-line
    : [0, () => { }]

  const handleNext = () => {
    setActiveStep(activeStep + 1)
  }

  const handleBack = () => {
    setActiveStep(activeStep - 1)
  }

  const { instructions, Component } = steps[activeStep]

  return (
    <Dialog
      title='Simulate match'
      open={!!match}
      onClose={onClose}
    >
      {match && <Context.Provider value={{ classes, loading }}>
        <Stepper activeStep={activeStep}>
          {steps.map(({ label }) => {
            const stepProps = {}
            const labelProps = {}

            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>

        <Fragment>
          <Typography
            variant="subtitle1"
            gutterBottom
            className={classes.instructions}
          >
            {instructions}
          </Typography>
          <div>
            <Component {...{ match, className: classes.component }} />

            <Button
              className={classes.button}
              size="small"
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              Back
              </Button>

            {activeStep < steps.length - 1 && <Button
              className={classes.button}
              size="small"
              variant="contained"
              color="primary"
              disabled={loading || !canProgress({ match, activeStep })}
              onClick={handleNext}
            >
              Next
            </Button>}
          </div>
        </Fragment>

      </Context.Provider>}
    </Dialog>
  )
}

const mapStateToProps = state => ({
  loading: isLoading(state),
})

export default compose(
  connect(mapStateToProps),
  withStyles(getStyles)
)(SimulateMatchDialog)


import React, { Fragment } from 'react'
import DialogContentText from '@material-ui/core/DialogContentText'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Dialog from '../Dialog'

const SendDialog = ({ data, match, onConfirm, onClose }) => {
  const invitations = data && Array.isArray(data) ? data : [data]
  const handleOnConfirm = () => {
    onConfirm(invitations)
  }

  return (
    <Dialog
      title='Send invitation'
      open={!!match}
      onConfirm={data && handleOnConfirm}
      onClose={onClose}
    >
      <Fragment>
        <DialogContentText>
          Send invitation to:
      </DialogContentText>
        <List>
          {data && invitations.map(({ email, firstName, lastName, teamId }) =>
            <ListItem key={email}>
              <ListItemText
                primary={`${firstName} ${lastName} (${email})`}
                secondary={teamId
                  ? 'Invitation can be sent'
                  : 'Cannot send invitation until no team is set'}
              />
            </ListItem>,
          )}
        </List>
      </Fragment>
    </Dialog>
  )
}

export default SendDialog

import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import { connect } from '../store'
import * as selectors from '../store/selectors'

const Loader = ({ loading }) => loading && <LinearProgress />

const mapStateToProps = state => ({
  loading: selectors.isLoading(state),
})

export default connect(mapStateToProps)(Loader)

import React from 'react'
import CreateIcon from '@material-ui/icons/Create'
import ActionButton from './ActionButton'

export default ({ id, ...props }) => <ActionButton
  title='Edit'
  to={`edit/${id}`}
  icon={CreateIcon}
  {...props}
/>

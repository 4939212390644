import { compose } from 'ramda'
import { connect } from '../store'
import withRouteMatch from './withRouteMatch'

export default (path, selector) => Component =>
  compose(
    withRouteMatch(path),
    connect((state, { match }) => ({
      data: match && selector(state, match),
    }))
  )(Component)

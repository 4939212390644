import React from 'react'
import Page from '../Page'
import copy from 'clipboard-copy'
import { withStyles } from '@material-ui/core/styles'
import getStyles from './getStyles'
import { IconButton } from '@material-ui/core'
import Icon from '@material-ui/icons/Assignment'

const endpoint = `${process.env.REACT_APP_REST_ENDPOINT_URL}/stats`
const apiKey = `wGHXNRdtd9gn7QFgWvP4bHJywVfmgVzNuWRx`

const LeaderboardsApi = ({ classes }) => (
  <Page title="Leaderboards API">
    <p>
      The following endpoint will return the statistics for all the teams within
      all the series.
    </p>

    <h3>Endpoint</h3>
    <div style={{ position: 'relative' }}>
      <pre className={classes.code}>{endpoint}</pre>
      <IconButton
        aria-label="Copy to clipboard"
        className={classes.button}
        onClick={() => copy(endpoint)}
      >
        <Icon fontSize="small" />
      </IconButton>
    </div>

    <h3>Method</h3>
    <div style={{ position: 'relative' }}>
      <pre className={classes.code}>GET</pre>
      <IconButton
        aria-label="Copy to clipboard"
        className={classes.button}
        onClick={() => copy('GET')}
      >
        <Icon fontSize="small" />
      </IconButton>
    </div>

    <h3>Authorization</h3>
    <p>
      Provide the API key in the{' '}
      <code className={classes.inlineCode}>x-api-key</code> HTTP header of the
      request.
    </p>
    <div style={{ position: 'relative' }}>
      <pre className={classes.code}>{apiKey}</pre>
      <IconButton
        aria-label="Copy to clipboard"
        className={classes.button}
        onClick={() => copy(apiKey)}
      >
        <Icon fontSize="small" />
      </IconButton>
    </div>

    <h3>Responses</h3>

    <h4>200: Success</h4>
    <div>Example:</div>
    <pre className={classes.code}>{example200}</pre>

    <h4>401: Unauthorized</h4>
    <p>
      The api key is incorrect or it wasn't provided in the corresponding HTTP
      header.
    </p>
    <div>Example:</div>
    <pre className={classes.code}>{example401}</pre>
  </Page>
)

const example200 = `[
  {
    series: 'string',
    teams: [
      {
        name: 'string',
        imageUrl: 'string',
        stats: {
          matches: 0,
          points: 0,
          wins: 0,
          draws: 0,
          losses: 0,
          score: 0,
          totalRuns: 0,
          homeRuns: 0,
          catches: 0,
          strikeOuts: 0,
          runOuts: 0,
        },
      },
    ],
  },
]`

const example401 = `{
  "messages": [
      "Unauthorized"
  ]
}`

export default withStyles(getStyles)(LeaderboardsApi)

import createStore from 'react-waterfall'
import * as actionsCreators from './actions'

const initialState = {
  initialized: false,
  user: null,
  loading: false,
  error: null,
  snackMessage: null,
  // Festival
  'api/invitations': [],
  'api/accounts': [],
  'api/teams': [],
  'api/matches': [],
  'api/venues': [],
  'api/regions': [],
  'api/rewards': [],
  'api/formFields': [],
  'api/news': [],

  // Free Games
  venues: [],
  regions: [],
  teams: [],
  series: [],
  accounts: [],
  rules: [],
}

const config = {
  initialState,
  actionsCreators,
}

export const { Provider, connect, actions } = createStore(config)

import React from 'react'
import { Link } from '@reach/router'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

export default ({ title, icon: Icon, color = 'primary', to, ...props }) =>
  <Tooltip title={title} leaveDelay={50}>
    <IconButton color={color} fontSize='small' component={to && Link} to={to} {...props}>
      <Icon />
    </IconButton>
  </Tooltip>

import React from 'react'
import { withStyles } from '@material-ui/core'
import { getVenues, getVenueById } from '../../store/selectors'
import CrudPage from '../CrudPage'
import * as models from '../../models'
import getStyles from './getStyles'

const Venues = ({ path }) =>
  <CrudPage {...{
    endpoint: 'api/venues',
    fields: models.venue,
    initialData: {
      name: '',
      address: '',
      lat: '',
      lon: '',
    },
    titleField: 'name',
    title: 'Venues',
    path,
    getItem: getVenueById,
    getItems: getVenues,
  }} />

export default withStyles(getStyles)(Venues)

import React, { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'
import DataForm from '../DataForm'
import Dialog from '../Dialog'
import getStyles from './getStyles'

class AddDialog extends PureComponent {
  constructor(props) {
    super(props)

    const { match, initialData } = props

    this.state = {
      data: initialData,
      open: !!match,
    }
  }

  handleChange = field => {
    const { data } = this.state

    this.setState({
      data: {
        ...data,
        ...field,
      },
    })
  }

  handleConfirm = () => {
    const { loading, onConfirm } = this.props

    !loading && onConfirm(this.state.data)
  }

  componentWillReceiveProps(props) {
    const { match, initialData } = props
    const open = !!match

    this.setState({
      open,
      ...!open && { data: initialData },
    })
  }

  render() {
    const { fields, title = 'Add new', loading, onClose } = this.props
    const { data, open } = this.state

    return (
      <Dialog
        title={typeof (title) === 'function' ? title(data) : title}
        maxWidth='sm'
        {...{ open, onClose, onConfirm: this.handleConfirm }}
      >
        <DataForm fields={fields} data={data} disabled={loading} onChange={this.handleChange} />
      </Dialog>
    )
  }
}

export default withStyles(getStyles)(AddDialog)

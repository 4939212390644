import React from 'react'
import { withStyles } from '@material-ui/core'
import { getNews, getNewsById } from '../../store/selectors'
import CrudPage from '../CrudPage'
import * as models from '../../models'
import getStyles from './getStyles'
import NewsSource from './NewsSource'

const News = ({ path }) => {
  return (
    <>
      <NewsSource />

      <CrudPage
        {...{
          endpoint: 'api/news',
          fields: models.news,
          initialData: {
            title: '',
            date: Date.now(),
            contentUrl: '',
            imageUrl: '',
          },
          titleField: 'name',
          title: 'News',
          path,
          getItem: getNewsById,
          getItems: getNews,
        }}
      />
    </>
  )
}

export default withStyles(getStyles)(News)

export default () => ({
  code: {
    backgroundColor: 'wheat',
    padding: '6px 10px',
    borderRadius: '4px',
    fontSize: '1em',
    overflow: 'scroll',
    paddingRight: '40px',
  },
  inlineCode: {
    border: '1px solid wheat',
    padding: '2px 4px',
    borderRadius: '4px',
    fontSize: '0.9em',
  },
  button: {
    position: 'absolute',
    top: '-8px',
    right: 0,
  },
})

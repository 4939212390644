export default ({ spacing, palette }) => ({
  root: {
    width: '100%',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  footerButton: {
    marginLeft: spacing.unit * 2,
  },
  buttons: {
    marginTop: spacing.unit * 3,
  },
  button: {
    marginRight: spacing.unit,
  },
  error: {
    backgroundColor: palette.error.dark,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: spacing.unit,
  },
  progressIcon: {
    marginLeft: spacing.unit * 2,
  },
  errors: {
    paddingLeft: spacing.unit * 4,
    opacity: 0.8,
  },
})

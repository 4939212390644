import DataTable from './DataTable'
import ActionButton from './ActionButton'
import DeleteButton from './DeleteButton'
import EditButton from './EditButton'
import ViewButton from './ViewButton'

export default DataTable
export {
  ActionButton,
  DeleteButton,
  EditButton,
  ViewButton,
}

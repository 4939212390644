import React from 'react'
import { withStyles } from '@material-ui/core'
import { navigate } from '@reach/router'
import moment from 'moment'
import SendIcon from '@material-ui/icons/Send'
import PlayIcon from '@material-ui/icons/PlayArrow'
import withMatchingItem from '../../lib/withMatchingItem'
import { actions } from '../../store'
import { getMatches, getMatchById } from '../../store/selectors'
import {
  ActionButton as ObjectActionButton,
  DeleteButton,
  EditButton,
  ViewButton,
} from '../DataTable'
import CrudPage from '../CrudPage'
import * as models from '../../models'
import { TARGET_TEAM } from '../Notifications/Notifications'
import SendNotificationDialog from './SendNotificationDialog'
import SimulateMatchDialog from '../SimulateMatchDialog'
import getStyles from './getStyles'

const endpoint = 'api/matches'

const RoutedSendNotificationDialog = withMatchingItem('send/:id', getMatchById)(SendNotificationDialog)

const RoutedSimulateMatchDialog = withMatchingItem('simulate/:id', getMatchById)(SimulateMatchDialog)

const SendNotificationButton = ({ id, ...props }) => <ObjectActionButton
  title='Send notification'
  to={`send/${id}`}
  icon={SendIcon}
  {...props}
/>
const SimulateMatchButton = ({ id, ...props }) => <ObjectActionButton
  title='Simulate match'
  to={`simulate/${id}`}
  icon={PlayIcon}
  {...props}
/>

const Matches = ({ classes, path }) => {
  const handleCloseDialog = () => {
    navigate(path.replace(/(\/\*$)/, ''))
  }

  const handleSendNotification = async ({ id, homeTeam, awayTeam, venue, date }) => {
    handleCloseDialog()

    const formattedDate = moment(date).format('HH:mm dddd, DD of MMM')
    const tasks = [
      actions.sendNotifications({
        type: TARGET_TEAM,
        target: homeTeam.id,
        title: 'Match Announcement',
        body: `Your match against ${awayTeam.name} will take place at ${venue.name} at ${formattedDate}`,
      }),
      actions.sendNotifications({
        type: TARGET_TEAM,
        target: awayTeam.id,
        title: 'Match Announcement',
        body: `Your match against ${homeTeam.name} will take place at ${venue.name} at ${formattedDate}`,
      }),
      actions.updateItem(
        endpoint,
        { id, notificationSent: true },
        true
      ),
    ]

    await Promise.all(tasks)
  }

  return (
    <CrudPage {...{
      endpoint,
      dependencies: ['api/venues', 'api/teams'],
      fields: models.match,
      initialData: {
        homeTeam: '',
        awayTeam: '',
        venue: '',
        date: Date.now(),
      },
      title: 'Matches',
      path,
      getItem: getMatchById,
      getItems: getMatches,
      objectActions: [
        ViewButton,
        EditButton,
        DeleteButton,
        SendNotificationButton,
        SimulateMatchButton,
      ],
    }}>
      <RoutedSendNotificationDialog
        classes={classes}
        onClose={handleCloseDialog}
        onConfirm={handleSendNotification}
      />
      <RoutedSimulateMatchDialog onClose={handleCloseDialog} />
    </CrudPage>
  )
}

export default withStyles(getStyles)(Matches)

import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'ramda'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { connect, actions } from '../../store'
import * as selectors from '../../store/selectors'
import Page from '../Page'
import getStyles from './getStyles'

class Login extends React.Component {
  static propTypes = {
    error: PropTypes.string,
  }

  state = {
    email: '',
    password: '',
    remember: false,
  }

  validateForm() {
    const { email, password } = this.state

    return email.length > 0 && password.length > 0
  }

  handleChange = ({ target }) => {
    this.setState({
      [target.id]: target.value,
    })
  }

  handleSubmit = async event => {
    event.preventDefault()

    actions.login(this.state)
  }

  render() {
    const { classes, error } = this.props

    return (
      <Page title='Login'>
        <Typography variant='body1' className={classes.instructions}>
          Log into admin.
        </Typography>

        {error && <Typography variant='body2' className={classes.error}>Error: {error}</Typography>}

        <form
          onSubmit={this.handleSubmit}
          className={classes.form}
          noValidate
          autoComplete='off'
        >
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                id='email'
                type='email'
                label='E-mail'
                value={this.state.email}
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id='password'

                type='password'
                label='Password'
                value={this.state.password}
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox
                  id='remember'
                  checked={this.state.remember}
                  onChange={(_, value) => this.handleChange({
                    target: { id: 'remember', value }
                  })}
                />}
                label='Remember (not secure)'
              />
            </Grid>
            <Grid item xs={12}>
              <Button className={classes.submit} variant='contained' color='primary' type='submit'>login</Button>
            </Grid>
          </Grid>
        </form>
      </Page>
    )
  }
}

const mapStateToProps = state => ({
  error: selectors.getErrorMessage(state),
})

export default compose(
  connect(mapStateToProps),
  withStyles(getStyles)
)(Login)

import React from 'react'
import { withStyles } from '@material-ui/core'
import { getFreeVenues, getFreeVenueById } from '../../../store/selectors'
import CrudPage from '../../CrudPage'
import * as models from '../../../models'
import getStyles from './getStyles'

const Venues = ({ path }) => (
  <CrudPage
    {...{
      key: path,
      endpoint: 'venues',
      dependencies: ['regions'],
      fields: models.freeVenue,
      initialData: {
        name: '',
        address: '',
        latitude: '',
        longitude: '',
      },
      titleField: 'name',
      title: 'Venues - Free Game',
      description:
        'Latitude and Longitude for existing venues are shown for reference but cannot be edited.',
      path,
      getItem: getFreeVenueById,
      getItems: getFreeVenues,
    }}
  />
)

export default withStyles(getStyles)(Venues)

export default ({ breakpoints, palette, spacing}) => ({
  root: {
    width: '100%',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  uploadDialogText: {
    cursor: 'pointer',
    border: '2px solid #eaeaea',
    display: 'block',
    borderStyle: 'dashed',
    padding: '1rem',
  },
})

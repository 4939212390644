import React, { Fragment, PureComponent } from 'react'
import { compose } from 'ramda'
import { Router, Location } from '@reach/router'
import {
  withStyles,
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import { DialogContent } from '@material-ui/core'
import { Provider as StoreProvider, actions, connect } from '../../store'
import { isInitialized, getSnackMessage, getError } from '../../store/selectors'
import withAuth from '../../lib/withAuth'
import AppBar from '../AppBar'
import Home from '../Home'
import Dashboard from '../Dashboard'
import Invitations from '../Invitations'
import Accounts from '../Accounts'
import Teams from '../Teams'
import Matches from '../Matches'
import Venues from '../Venues'
import Regions from '../Regions'
import Rewards from '../Rewards'
import FormFields from '../FormFields'
import News from '../News'
import Notifications from '../Notifications'
import Logout from '../Logout'
import Dialog from '../Dialog'
import getStyles from './getStyles'
import FreeVenues from '../FreeGames/Venues'
import FreeRegions from '../FreeGames/Regions'
import FreeTeams from '../FreeGames/Teams'
import FreeSeries from '../FreeGames/Series'
import FreeAccounts from '../FreeGames/Accounts'
import Rules from '../Rules'
import LeaderboardsApi from '../LeaderboardsApi'

const DashboardWithAuth = withAuth(Dashboard)
const InvitationsWithAuth = withAuth(Invitations)
const AccountsWithAuth = withAuth(Accounts)
const TeamsWithAuth = withAuth(Teams)
const MatchesWithAuth = withAuth(Matches)
const VenuesWithAuth = withAuth(Venues)
const RegionsWithAuth = withAuth(Regions)
const RewardsWithAuth = withAuth(Rewards)
const FormFieldsWithAuth = withAuth(FormFields)
const NewsWithAuth = withAuth(News)
const NotificationsWithAuth = withAuth(Notifications)
const FreeVenuesWithAuth = withAuth(FreeVenues)
const FreeRegionsWithAuth = withAuth(FreeRegions)
const FreeTeamsWithAuth = withAuth(FreeTeams)
const FreeSeriesWithAuth = withAuth(FreeSeries)
const FreeAccountsWithAuth = withAuth(FreeAccounts)
const RulesWithAuth = withAuth(Rules)
const LeaderboardsApiWithAuth = withAuth(LeaderboardsApi)

class App extends PureComponent {
  async componentDidMount() {
    await actions.initializeApp()
  }

  async componentDidUpdate() {
    const { error } = this.props

    if (error && [401, 403].includes(error.code)) {
      await actions.logout()
    }
  }

  render() {
    const { classes, initialized, snackMessage, error } = this.props

    return (
      <Fragment>
        <AppBar />

        <main className={classes.main}>
          {initialized && (
            <Router primary={false}>
              <Home path="/" />
              <Logout path="/logout" />
              <DashboardWithAuth path="/dashboard" />

              <FreeVenuesWithAuth path="/free-venues/*" />
              <FreeRegionsWithAuth path="/free-regions/*" />
              <FreeTeamsWithAuth path="/free-teams/*" />
              <FreeSeriesWithAuth path="/free-series/*" />
              <FreeAccountsWithAuth path="/free-accounts/*" />
              <RulesWithAuth path="/rules/*" />

              <InvitationsWithAuth path="/invitations/*" />
              <AccountsWithAuth path="/accounts/*" />
              <TeamsWithAuth path="/teams/*" />
              <MatchesWithAuth path="/matches/*" />
              <VenuesWithAuth path="/venues/*" />
              <RegionsWithAuth path="/regions/*" />
              <RewardsWithAuth path="/rewards/*" />
              <FormFieldsWithAuth path="/form-fields/*" />
              <NewsWithAuth path="/news/*" />
              <NotificationsWithAuth path="/notifications/*" />
              <LeaderboardsApiWithAuth path="/leaderboards-api" />
            </Router>
          )}

          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            open={!!snackMessage}
            onClose={actions.clearSnackMessage}
            ContentProps={{
              'aria-describedby': 'snack-message-id',
            }}
            message={<span id="snack-message-id">{snackMessage}</span>}
          />

          <Dialog
            title={`Error ${error && error.code}`}
            open={!!error}
            maxWidth="xs"
            onClose={() => actions.clearError()}
          >
            <DialogContent classes={{ root: classes.dialogContent }}>
              <pre className={classes.dialogContentError}>
                {error && error.message}
              </pre>
            </DialogContent>
          </Dialog>
        </main>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  initialized: isInitialized(state),
  snackMessage: getSnackMessage(state),
  error: getError(state),
})

const CompositeApp = compose(
  connect(mapStateToProps),
  withStyles(getStyles)
)(App)

const ThemedApp = () => {
  const festivalTheme = createMuiTheme({
    typography: { useNextVariants: true },
    palette: {
      primary: { main: '#0e28b2' },
      secondary: { main: '#ed6233' },
    },
  })
  const freeGamesTheme = createMuiTheme({
    typography: { useNextVariants: true },
    palette: {
      primary: { main: '#ed6233' },
      secondary: { main: '#0e28b2' },
    },
  })

  return (
    <Location>
      {({ location: { pathname } }) => {
        return (
          <MuiThemeProvider
            theme={
              pathname.startsWith('/free-') ? freeGamesTheme : festivalTheme
            }
          >
            <CompositeApp />
          </MuiThemeProvider>
        )
      }}
    </Location>
  )
}

export default () => (
  <StoreProvider>
    <ThemedApp />
  </StoreProvider>
)

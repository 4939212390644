import React from 'react'
import ActionButton from './ActionButton'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'

export default ({ id, ...props }) => <ActionButton
  title='View details'
  to={`view/${id}`}
  icon={OpenInNewIcon}
  {...props}
/>

import React from 'react'
import { Link } from '@reach/router'
import Typography from '@material-ui/core/Typography'
import Page from './Page'

export default () =>
  <Page title='Logout'>
    <Typography>
      Successfully logged out.
    </Typography>
    <Link to='/'>home</Link>
  </Page>

export default ({ breakpoints, palette, spacing}) => ({
  root: {
    width: '100%',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  footerButton: {
    marginLeft: spacing.unit * 2,
  },
})

import React, { useEffect, useState } from 'react'
import { withStyles, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { connect, actions } from '../../store'
import {
  getAccountsForSelect,
  getTeamsForSelect,
  getMatchesForSelect,
} from '../../store/selectors'
import Page from '../Page'
import getStyles from './getStyles'

export const TARGET_MATCH = 'match'
export const TARGET_TEAM = 'team'
export const TARGET_ACCOUNT = 'account'

const targetSelectorMap = {
  [TARGET_ACCOUNT]: getAccountsForSelect,
  [TARGET_TEAM]: getTeamsForSelect,
  [TARGET_MATCH]: getMatchesForSelect,
}

const mapStateToProps = (state, { target }) => ({
  items: target && targetSelectorMap[target](state),
})

const TargetSelect = connect(mapStateToProps)(({ items = [], value, onChange }) => (
  <FormControl>
    <InputLabel htmlFor='select'>Message target:</InputLabel>
    <Select
      value={value || ''}
      onChange={onChange}
      inputProps={{
        id: 'select',
      }}
      autoWidth
    >
      {items.map(({ value, label }, i) =>
        <MenuItem key={i} value={value}>{label}</MenuItem>
      )}
    </Select>
  </FormControl>
))

const initialData = {
  type: '',
  target: '',
  title: '',
  body: '',
}

const Notifications = ({ classes }) => {
  const [initialized, setInitialized] = useState(false)
  const [data, setData] = useState(initialData)

  const handleChangeData = field => ({ target }) => {
    setData({
      ...data,
      [field]: target.value,
      ...field === 'type' && { target: '' },
    })
  }

  const handleSubmit = async () => {
    await actions.sendNotifications(data)
    setData(initialData)
  }

  const isValid = () => !(data.target && data.title && data.body)

  useEffect(() => {
    if (!initialized) {
      (async () => {
        await actions.fetchItems('matches')
        setInitialized(true)
      })()
    }
  })

  return (
    <Page title='Notifications'>
      <Typography className={classes.instructionText}>
        You can send a message directly to all participants of a match,
        specific team only or directly to a specific player.
      </Typography>

      <div className={classes.target}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend">Target type:</FormLabel>
          <RadioGroup
            aria-label='Target type'
            name='type'
            className={classes.group}
            value={data.type}
            onChange={handleChangeData('type')}
          >
            <FormControlLabel value={TARGET_ACCOUNT} control={<Radio />} label='Account' />
            <FormControlLabel value={TARGET_TEAM} control={<Radio />} label='Team' />
            <FormControlLabel value={TARGET_MATCH} control={<Radio />} label='Match' />
          </RadioGroup>
        </FormControl>
      </div>

      {data.type && <form
        className={classes.form}
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit}
      >
        <TargetSelect
          target={data.type}
          value={data.target}
          onChange={handleChangeData('target')}
        />
        <TextField
          label='Message Title'
          className={classes.textField}
          value={data.title}
          onChange={handleChangeData('title')}
          margin='normal'
        />
        <TextField
          label='Message Body'
          className={classes.textField}
          value={data.body}
          onChange={handleChangeData('body')}
          margin='normal'
          multiline
          rows={2}
        />
        <Button
          variant='contained'
          color='primary'
          disabled={isValid()}
          className={classes.button}
          onClick={handleSubmit}
        >
          send message
        </Button>
      </form>}
    </Page>
  )
}

export default withStyles(getStyles)(Notifications)


import React, { Fragment } from 'react'
import DialogContentText from '@material-ui/core/DialogContentText'
import Dialog from '../Dialog'

const SendDialog = ({ data, onConfirm, onClose }) =>
  <Dialog
    title='Send notification'
    open={!!data}
    onConfirm={() => onConfirm(data)}
    onClose={onClose}
  >
    <Fragment>
      {data && <DialogContentText>
        Send notification to match "{data.id}"
      </DialogContentText>}
    </Fragment>
  </Dialog>

export default SendDialog

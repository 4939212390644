import React from 'react'
import { withStyles } from '@material-ui/core'
import { getAccounts, getAccountById } from '../../store/selectors'
import CrudPage from '../CrudPage'
import * as models from '../../models'
import getStyles from './getStyles'

const Accounts = ({ path }) =>
  <CrudPage {...{
    endpoint: 'api/accounts',
    dependencies: ['api/teams'],
    actions: [],
    fields: models.account,
    titleField: 'name',
    title: 'Accounts',
    path,
    getItem: getAccountById,
    getItems: getAccounts,
    rowsPerPageOptions: [15, 25, 50, 100, 500],
  }} />

export default withStyles(getStyles)(Accounts)

export default () => ({
  dialogContent: {
    padding: '0 !important',
  },
  dialogContentError: {
    whiteSpace: 'pre-line',
  },
  main: {
    paddingBottom: '30px',
  },
})

import React, { Fragment, useState, useContext } from 'react'
import { pluck, map, sum, pipe } from 'ramda'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { actions } from '../../store'
import SpinButton from '../SpinButton'
import { Context } from './SimulateMatchDialog'

const statFields = ['totalRuns', 'homeRuns', 'catches', 'strikeOuts', 'runOuts']

const getScore = ({ totalRuns, homeRuns }) => totalRuns + homeRuns * 2

const getMatchPoints = pipe(
  pluck('stats'),
  map(getScore),
  sum
)

const Input = ({ classes, name, value, disabled, handleChange }) => <TextField
  label={name}
  value={value + ''}
  type="number"
  disabled={disabled}
  className={classes.scoringInput}
  onChange={({ target }) => handleChange({ name, value: +target.value })}
/>

const Player = ({ player, matchId, teamId, locked, setLocked }) => {
  const [stats, setStats] = useState(player.stats)
  const [dirty, setDirty] = useState(false)
  const { classes, loading } = useContext(Context)
  const disabled = loading || (locked && locked !== player.id)

  const setStat = ({ name, value }) => setStats({
    ...stats,
    [name]: Math.max(0, value),
  })

  const handleChange = async stat => {
    setStat(stat)
    setLocked(player.id)
    setDirty(true)
  }

  const handleSave = async () => {
    const data = {
      matchId,
      teamId,
      playerId: player.id,
      payload: {
        stats,
        positionId: player.positionId,
      },
    }

    await actions.updateMatchPlayer(data)
    setLocked(null)
    setDirty(false)
  }

  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Typography gutterBottom>{player.firstName} {player.lastName}</Typography>
        </Grid>
        <Grid item xs={12}>
          {statFields.map(name =>
            <Input {...{
              key: name,
              name,
              value: stats[name],
              classes,
              disabled,
              handleChange,
            }} />
          )}

          <SpinButton
            size="small"
            color="primary"
            disabled={!dirty || disabled}
            loading={loading}
            onClick={handleSave}
          >
            save
          </SpinButton>
        </Grid>
      </Grid>
    </Fragment>
  )
}

const Team = ({ match, side, locked, setLocked }) => {
  const teamId = match[`${side}TeamId`]
  const teamName = match[`${side}Team`].name
  const players = match.players.filter(player => player.teamId === teamId)
  const points = getMatchPoints(players)
  const { classes } = useContext(Context)

  return (
    <Fragment>
      <Typography variant="body2" gutterBottom>
        {teamName} ({points})
      </Typography>

      <ul className={classes.list}>
        {players.map(player =>
          <li key={player.id}>
            <Player {...{ player, matchId: match.id, teamId, locked, setLocked }} />
          </li>
        )}
      </ul>
    </Fragment>
  )
}

const Scoring = ({ className, match }) => {
  const [locked, setLocked] = useState(null)

  return (
    <Grid container className={className} spacing={16}>
      <Grid item xs={12} md={6}>
        <Team side='home' match={match} locked={locked} setLocked={setLocked} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Team side='away' match={match} locked={locked} setLocked={setLocked} />
      </Grid>
    </Grid>
  )
}

export default Scoring


import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import DialogContentText from '@material-ui/core/DialogContentText'
import Dialog from '../Dialog'
import getStyles from './getStyles'

const DeleteDialog = ({ classes, titleField, data, onConfirm, onClose }) =>
  <Dialog
    title={data ? 'Delete ' + (data[titleField] || '') : ''}
    open={!!data}
    onConfirm={() => onConfirm(data)}
    onClose={onClose}
  >
    <DialogContentText>
      Are you sure to remove?
    </DialogContentText>
  </Dialog>

export default withStyles(getStyles)(DeleteDialog)

import React, { useCallback, useEffect, useState } from 'react'
import { compose } from 'ramda'
import {
  Button,
  CircularProgress,
  Snackbar,
  SnackbarContent,
  TextField,
  withStyles,
} from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'
import SaveIcon from '@material-ui/icons/Save'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import { connect, actions } from '../../store'
import { fetchWithAuth } from '../../firebase'
import getStyles from './getStyles'
import Page from '../Page'

const urlRegExp =
  // eslint-disable-next-line no-useless-escape
  /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
const numRegExp = /^[1-9]\d*$/

const NewsSource = ({ classes }) => {
  const [newsRssUrl, setNewsRssUrl] = useState('')
  const [newsArticlesCount, setNewsArticlesCount] = useState(0)
  const [errors, setErrors] = useState([])
  const [fetchError, setFetchError] = useState()
  const [isSaving, setIsSaving] = useState(false)
  const [isFetching, setIsFetching] = useState(false)

  const onClickSave = useCallback(async () => {
    try {
      setIsSaving(true)
      const body = JSON.stringify({
        newsRssUrl,
        newsArticlesCount,
      })
      const config = await fetchWithAuth('/config', { method: 'PATCH', body })

      setNewsRssUrl(config?.newsRssUrl || '')
      setNewsArticlesCount(config?.newsArticlesCount || 0)
      actions.showSnackMessage('Config saved successfully!')
    } catch (error) {
      console.log(error)
      if (error?.message) {
        let err = error.message
        if (error.message.includes('\n')) {
          err = error.message.split('\n').map((e, i) => <div key={i}>{e}</div>)
        }
        setFetchError(err)
      }
    }
    setIsSaving(false)
  }, [newsArticlesCount, newsRssUrl])

  const onClickFetchNow = useCallback(async () => {
    try {
      setIsFetching(true)
      await fetchWithAuth('/fetchnews')
      await actions.fetchItems('api/news')
      actions.showSnackMessage('News updated successfully!')
    } catch (error) {
      console.log(error)
      setFetchError(error?.message || 'There was a problem fetching the News.')
    }
    setIsFetching(false)
  }, [])

  useEffect(() => {
    const errors = []
    if (!urlRegExp.test(newsRssUrl)) errors.push('newsRssUrl')
    if (!numRegExp.test(newsArticlesCount)) errors.push('newsArticlesCount')
    setErrors(errors)
  }, [newsArticlesCount, newsRssUrl])

  useEffect(() => {
    ;(async () => {
      try {
        const config = await fetchWithAuth('/config')
        setNewsRssUrl(config?.newsRssUrl || '')
        setNewsArticlesCount(config?.newsArticlesCount || 0)
      } catch (error) {
        setFetchError(
          error?.message ||
            'There was a problem fetching the News configuration.'
        )
      }
    })()
  }, [])

  return (
    <>
      <Page title="News Source">
        <TextField
          label="RSS URL"
          fullWidth
          value={newsRssUrl}
          onChange={e => setNewsRssUrl(e?.target?.value)}
          margin="normal"
          error={errors.includes('newsRssUrl')}
        />
        <TextField
          label="Number of articles"
          type="number"
          value={newsArticlesCount}
          onChange={e => setNewsArticlesCount(+e?.target?.value)}
          margin="normal"
          error={errors.includes('newsArticlesCount')}
        />

        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="primary"
            disabled={!!errors.length || isSaving || isFetching}
            className={classes.button}
            onClick={onClickSave}
          >
            <SaveIcon className={classes.icon} />
            {isSaving ? 'Saving...' : 'Save News Config'}
            {isSaving && (
              <CircularProgress
                color="secondary"
                size={24}
                className={classes.progressIcon}
              />
            )}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={!!errors.length || isSaving || isFetching}
            className={classes.button}
            onClick={onClickFetchNow}
          >
            <DownloadIcon className={classes.icon} />
            {isFetching ? 'Fetching...' : 'Fetch News Now'}
            {isFetching && (
              <CircularProgress
                color="secondary"
                size={24}
                className={classes.progressIcon}
              />
            )}
          </Button>
        </div>
      </Page>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={!!fetchError}
        onClose={() => setFetchError()}
      >
        <SnackbarContent
          className={classes.error}
          aria-describedby="client-snackbar"
          message={
            <div>
              <div className={classes.message}>
                <ErrorIcon className={classes.icon} />
                <span>Sorry there was an error</span>
              </div>
              <div className={classes.errors}>{fetchError}</div>
            </div>
          }
        />
      </Snackbar>
    </>
  )
}

export default compose(
  connect(() => {}),
  withStyles(getStyles)
)(NewsSource)

import React from 'react'
import Button from '@material-ui/core/Button'
import BaseDialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'

const Dialog = ({ children, title, textContent, maxWidth = 'md', onClose, onConfirm, ...props }) =>
  <BaseDialog
    fullWidth={true}
    maxWidth={maxWidth}
    aria-labelledby='dialog'
    onClose={onClose}
    {...props}
  >
    <DialogTitle id='dialog'>{title}</DialogTitle>
    <DialogContent>
      {textContent && <DialogContentText>{textContent}</DialogContentText>}
      {children}
    </DialogContent>
    <DialogActions>
      {onConfirm && <Button onClick={onConfirm} color='primary'>Ok</Button>}
      <Button onClick={onClose} color={onConfirm ? 'default' : 'primary'}>
        {onConfirm ? 'Cancel' : 'Close'}
      </Button>
    </DialogActions>
  </BaseDialog>

export default Dialog

export default ({ spacing }) => ({
  root: {
    width: '100%',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  footerButton: {
    marginLeft: spacing.unit * 2,
  },
  footerLink: {
    marginLeft: spacing.unit * 2,
    padding: '8px 0',
    display: 'inline-block',
  },
})

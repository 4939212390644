import React from 'react'
import { withStyles } from '@material-ui/core'
import { getFreeTeams, getFreeTeamById } from '../../../store/selectors'
import CrudPage from '../../CrudPage'
import * as models from '../../../models'
import getStyles from './getStyles'

const Teams = ({ path }) => (
  <CrudPage
    {...{
      endpoint: 'teams',
      dependencies: ['regions'],
      fields: models.freeTeam,
      titleField: 'name',
      title: 'Teams - Free Game',
      path,
      actions: [], // Remove create button, since for now a team must be created by a player
      getItem: getFreeTeamById,
      getItems: getFreeTeams,
    }}
  />
)

export default withStyles(getStyles)(Teams)

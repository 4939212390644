import React, { PureComponent } from 'react'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import {
  InlineDateTimePicker,
  MuiPickersUtilsProvider,
} from 'material-ui-pickers'
import MomentUtils from '@date-io/moment'
import getStyles from './getStyles'

const Input = ({
  classes,
  data,
  name,
  label,
  type,
  values,
  selectValue,
  format,
  disabled,
  onChange,
}) => {
  const value = data[name]
  let component

  switch (type) {
    case 'select':
      component = (
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor={'edit-dialog-' + name}>{label}</InputLabel>
          <Select
            value={value || ''}
            onChange={({ target }) => onChange({ [name]: target.value })}
            inputProps={{
              id: 'edit-dialog-' + name,
            }}
            margin="none"
            autoWidth
            disabled={disabled}
          >
            {values.map(({ value, label }, i) => (
              <MenuItem key={i} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )
      break

    case 'native-select':
      component = (
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor={'edit-dialog-' + name}>{label}</InputLabel>
          <Select
            native
            value={value || ''}
            onChange={({ target }) => onChange({ [name]: target.value })}
            inputProps={{
              id: 'edit-dialog-' + name,
            }}
            margin="none"
            autoWidth
            disabled={disabled}
          >
            {values.map(({ value, label }, i) => (
              <option key={i} value={value}>
                {label}
              </option>
            ))}
          </Select>
        </FormControl>
      )
      break

    case 'checkbox':
      component = (
        <FormControlLabel
          control={
            <Checkbox
              checked={value || false}
              onChange={({ target }) => onChange({ [name]: target.checked })}
              value={name}
              disabled={disabled}
            />
          }
          label={label}
        />
      )
      break

    case 'datetime':
      component = (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <InlineDateTimePicker
            keyboard
            ampm={false}
            label={label}
            value={moment(value)}
            onChange={date => onChange({ [name]: moment(date).unix() * 1000 })}
            format={format}
            disabled={disabled}
          />
        </MuiPickersUtilsProvider>
      )
      break

    case 'number':
      component = (
        <TextField
          type="number"
          label={label}
          value={value}
          margin="none"
          fullWidth
          disabled={disabled}
          onChange={({ target }) => onChange({ [name]: target.valueAsNumber })}
        />
      )
      break

    default:
      component = (
        <TextField
          label={label}
          value={selectValue ? selectValue(value) : value}
          margin="none"
          fullWidth
          disabled={disabled}
          onChange={({ target }) => onChange({ [name]: target.value })}
        />
      )
      break
  }

  return (
    <Grid item xs={12} className={classes.row}>
      {component}
    </Grid>
  )
}

class DataForm extends PureComponent {
  render() {
    const { classes, fields, data, disabled, onChange } = this.props

    if (!data) {
      return null
    }

    return (
      <Grid container>
        {fields.map(
          ({ name, label, type, values, selectValue, disabled: d }, i) => (
            <Input
              key={i}
              {...{
                classes,
                data,
                name,
                label,
                type,
                values,
                selectValue,
                disabled: d || disabled,
                onChange,
              }}
            />
          )
        )}
      </Grid>
    )
  }
}

export default withStyles(getStyles)(DataForm)

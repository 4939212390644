
import React from 'react'
import DialogContentText from '@material-ui/core/DialogContentText'
import Dropzone from 'react-dropzone'
import Dialog from '../Dialog'

const UploadDialog = ({ classes, match, onUpload, onClose }) =>
  <Dialog title='Upload CSV' open={!!match} onClose={onClose}>
    <Dropzone accept={'.csv'} onDrop={onUpload}>
      {({ getRootProps, getInputProps }) => (
        <DialogContentText {...getRootProps()}>
          <input {...getInputProps()} />
          <span className={classes.uploadDialogText}>
            Drag 'n' drop some files here, or click to select files
          </span>
        </DialogContentText>
      )}
    </Dropzone>
  </Dialog>

export default UploadDialog

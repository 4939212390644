import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '@reach/router'
import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MailIcon from '@material-ui/icons/Mail'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import PeopleIcon from '@material-ui/icons/People'
import AlarmIcon from '@material-ui/icons/Alarm'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import MapIcon from '@material-ui/icons/Map'
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied'
import ListIcon from '@material-ui/icons/List'
import RssFeedIcon from '@material-ui/icons/RssFeed'
import NotificationsIcon from '@material-ui/icons/Notifications'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import SportsBaseballIcon from '@material-ui/icons/SportsBaseball'
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents'
import CodeIcon from '@material-ui/icons/Code'
import GavelIcon from '@material-ui/icons/Gavel'
import getStyles from './getStyles'
import { Collapse } from '@material-ui/core'

const menuItemsFestival = [
  { path: '/invitations', label: 'Invitations', Icon: MailIcon },
  { path: '/accounts', label: 'Accounts', Icon: AccountCircleIcon },
  { path: '/teams', label: 'Teams', Icon: PeopleIcon },
  { path: '/matches', label: 'Matches', Icon: AlarmIcon },
  { path: '/venues', label: 'Venues', Icon: LocationCityIcon },
  { path: '/regions', label: 'Regions', Icon: MapIcon },
  { path: '/rewards', label: 'Rewards', Icon: SentimentSatisfiedIcon },
]

const menuItemsFree = [
  { path: '/free-accounts', label: 'Accounts', Icon: AccountCircleIcon },
  { path: '/free-venues', label: 'Venues', Icon: LocationCityIcon },
  { path: '/free-regions', label: 'Regions', Icon: MapIcon },
  { path: '/free-teams', label: 'Teams', Icon: PeopleIcon },
  { path: '/free-series', label: 'Series', Icon: EmojiEventsIcon },
]

const menuItemsCommon = [
  { path: '/form-fields', label: 'Form fields', Icon: ListIcon },
  { path: '/news', label: 'News', Icon: RssFeedIcon },
  { path: '/rules', label: 'Rules', Icon: GavelIcon },
  { path: '/notifications', label: 'Notifications', Icon: NotificationsIcon },
  { path: '/leaderboards-api', label: 'Leaderboards API', Icon: CodeIcon },
]

const Menu = ({ classes }) => {
  const [isFestivalOpen, setIsFestivalOpen] = React.useState(true)
  const [isFreeGamesOpen, setIsFreeGamesOpen] = React.useState(true)
  const handleFestivalClick = e => {
    e.stopPropagation()
    setIsFestivalOpen(!isFestivalOpen)
  }
  const handleFreeGamesClick = e => {
    e.stopPropagation()
    setIsFreeGamesOpen(!isFreeGamesOpen)
  }

  return (
    <div className={classes.list}>
      <List component="nav">
        <ListItem button onClick={handleFreeGamesClick}>
          <ListItemIcon>
            <SportsBaseballIcon style={{ color: '#ed6233' }} />
          </ListItemIcon>
          <ListItemText primary="Free Games" />
          {isFreeGamesOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={isFreeGamesOpen}>
          <List component="div" disablePadding>
            {menuItemsFree.map(({ path, label, Icon }) => (
              <CustomListItem
                {...{ key: path, path, label, Icon, className: classes.nested }}
              />
            ))}
          </List>
        </Collapse>

        <Divider />

        <ListItem button onClick={handleFestivalClick}>
          <ListItemIcon>
            <SportsBaseballIcon style={{ color: '#0e28b2' }} />
          </ListItemIcon>
          <ListItemText primary="Festival" />
          {isFestivalOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={isFestivalOpen}>
          <List component="div" disablePadding>
            {menuItemsFestival.map(({ path, label, Icon }) => (
              <CustomListItem
                {...{ key: path, path, label, Icon, className: classes.nested }}
              />
            ))}
          </List>
        </Collapse>

        <Divider />

        <List component="div" disablePadding>
          {menuItemsCommon.map(({ path, label, Icon }) => (
            <CustomListItem {...{ key: path, path, label, Icon }} />
          ))}
        </List>
      </List>
    </div>
  )
}

const CustomListItem = ({ path, label, Icon, className }) => (
  <ListItem component={Link} key={path} to={path} button className={className}>
    <ListItemIcon>
      <Icon />
    </ListItemIcon>
    <ListItemText primary={label} />
  </ListItem>
)

Menu.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(getStyles)(Menu)

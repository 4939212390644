import React from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import ActionButton from './ActionButton'

export default ({ id, ...props }) => <ActionButton
  title='Delete'
  to={`delete/${id}`}
  icon={DeleteIcon}
  {...props}
/>

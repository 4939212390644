import React from 'react'
import Page from './Page'
import MediaCard from './MediaCard'

export default () =>
  <Page title="Dashboard">
    <MediaCard
      title="Support"
      text="You can find all the sections of this CMS in the left-hand side hamburger menu. Support chat is available as well, please tap on the Chat icon on your screen. Should you need urgent support please contact us."
      imageUrl="/assets/images/customercare.jpg"
      actionTitle0="Call"
      actionUrl0="tel:+‭448444878107‬"
      actionTitle1="Email"
      actionUrl1="mailto:support@londonappworks.co.uk‬"
    />
  </Page>

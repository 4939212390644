export default () => ({
  dialog: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    width: '52px',
  },
  table: {
    width: '28px',
  },
})

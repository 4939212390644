import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as R from 'ramda'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import BaseTableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import BaseToolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import Tooltip from '@material-ui/core/Tooltip'
import getStyles from './getStyles'
import getToolbarStyles from './getToolbarStyles'
import AccessIcon from '../AccessIcon'

const findLabel = (values, value) =>
  R.compose(R.path([0, 'label']), R.filter(R.whereEq({ value })))(values)

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy)
}

class TableHead extends React.Component {
  static propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    fields: PropTypes.array.isRequired,
    actions: PropTypes.bool,
  }

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property)
  }

  render() {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      fields,
      actions,
    } = this.props

    return (
      <BaseTableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={!!rowCount && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {fields.map(({ name, padding, align, label }) => (
            <TableCell
              {...{ key: name, align, padding }}
              sortDirection={orderBy === name ? order : false}
            >
              <Tooltip
                title="Sort"
                // placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                enterDelay={300}
              >
                <TableSortLabel
                  active={orderBy === name}
                  direction={order}
                  onClick={this.createSortHandler(name)}
                >
                  {label}
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          ))}
          {actions && <TableCell />}
        </TableRow>
      </BaseTableHead>
    )
  }
}

let Toolbar = props => {
  const { selected, classes, bulkActions } = props

  const numSelected = selected.length

  return (
    <BaseToolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 && (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <React.Fragment>
            {bulkActions.map((Component, i) => (
              <Component
                className={classes.bulkActions}
                key={i}
                selected={selected}
              />
            ))}
          </React.Fragment>
        ) : // <Tooltip title="Filter list">
        //   <IconButton aria-label="Filter list">
        //     <FilterListIcon />
        //   </IconButton>
        // </Tooltip>
        null}
      </div>
    </BaseToolbar>
  )
}

Toolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  selected: PropTypes.array.isRequired,
}

Toolbar = withStyles(getToolbarStyles)(Toolbar)

const findIdField = R.compose(R.path(['name']), R.find(R.propEq('id', true)))

class DataTable extends React.Component {
  static propTypes = {
    fields: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    actions: PropTypes.array,
    rowsPerPageOptions: PropTypes.array,
  }

  static getDerivedStateFromProps = (props, state) => {
    const idField = findIdField(props.fields) || state.idField
    const findById = id => R.find(R.propEq(idField, id))(props.data)

    return {
      ...state,
      selected: state.selected.filter(findById),
      idField,
    }
  }

  state = {
    order: 'asc',
    orderBy: 'calories',
    selected: [],
    page: 0,
    rowsPerPage: 15,
    idField: 'id',
  }

  handleRequestSort = (event, property) => {
    const orderBy = property
    let order = 'desc'

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }

    this.setState({ order, orderBy })
  }

  handleSelectAllClick = event => {
    const { data } = this.props
    const { idField } = this.state

    if (event.target.checked) {
      this.setState(() => ({ selected: data.map(n => n[idField]) }))
      return
    }
    this.setState({ selected: [] })
  }

  handleClick = (_, id) => {
    const { selected } = this.state
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    this.setState({ selected: newSelected })
  }

  handleChangePage = (event, page) => {
    this.setState({ page })
  }

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value })
  }

  isSelected = id => this.state.selected.indexOf(id) !== -1

  render() {
    const {
      classes,
      actions = [],
      bulkActions = [],
      fields,
      data,
      rowsPerPageOptions = [15, 25, 50, 100],
    } = this.props
    const { order, orderBy, selected, rowsPerPage, page, idField } = this.state

    const resolvedData = data.map(item => {
      const getDisplayValue = ({
        name,
        type,
        values,
        format,
        getId,
        selectValue = s => s,
      }) => {
        const value = item[name]
        const selectedValue = selectValue(getId ? getId(value) : value)

        if (selectedValue === undefined) {
          if (type === 'access')
            return <AccessIcon value="legacy" type="table" />
          return '-'
        }

        switch (type) {
          case 'datetime':
            return moment(selectedValue).format(format)
          case 'select':
          case 'native-select':
            return findLabel(values, selectedValue)
          case 'access':
            return <AccessIcon value={selectedValue} type="table" />
          default:
            return selectedValue.toString()
        }
      }

      const id = item[idField]
      const values = fields.map(field => ({
        name: field.name,
        value: getDisplayValue(field),
        tooltip: field.tooltip && field.tooltip(item[field.name]),
        ui: field.ui,
      }))

      return {
        ...values.reduce(
          (fields, { name, value }) => ({
            ...fields,
            [name]: value,
          }),
          {}
        ),
        id,
        values,
        isSelected: this.isSelected(id),
        toggleSelect: event => this.handleClick(event, id),
      }
    })

    return (
      <React.Fragment>
        <Toolbar selected={selected} bulkActions={bulkActions} />

        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <TableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={resolvedData.length}
              fields={fields}
              actions={!!actions.length}
            />
            <TableBody>
              {stableSort(resolvedData, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(({ id, values, isSelected, toggleSelect }) => (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={id}
                    selected={isSelected}
                  >
                    <TableCell padding="checkbox" onClick={toggleSelect}>
                      <Checkbox checked={isSelected} />
                    </TableCell>
                    {values.map(({ name, value, tooltip, ui }) => (
                      <TableCell
                        onClick={toggleSelect}
                        {...{ key: name, ...ui }}
                      >
                        {tooltip ? (
                          <Tooltip title={tooltip}>
                            <span>{value}</span>
                          </Tooltip>
                        ) : (
                          value
                        )}
                      </TableCell>
                    ))}
                    <TableCell className={classes.actions}>
                      {actions.map((Component, i) => (
                        <Component
                          className={classes.actions}
                          key={i}
                          id={id}
                        />
                      ))}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>

        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </React.Fragment>
    )
  }
}

export default withStyles(getStyles)(DataTable)

import React from 'react'
import { withStyles } from '@material-ui/core'
import { getFreeRegions, getFreeRegionById } from '../../../store/selectors'
import CrudPage from '../../CrudPage'
import * as models from '../../../models'
import getStyles from './getStyles'

const Regions = ({ path }) => (
  <CrudPage
    {...{
      endpoint: 'regions',
      fields: models.freeRegion,
      initialData: {
        name: '',
      },
      titleField: 'name',
      title: 'Regions - Free Game',
      path,
      getItem: getFreeRegionById,
      getItems: getFreeRegions,
    }}
  />
)

export default withStyles(getStyles)(Regions)

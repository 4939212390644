import firebase from 'firebase/app'
import 'firebase/auth'
import * as R from 'ramda'

const {
  REACT_APP_REST_ENDPOINT_URL,
  REACT_APP_API_KEY,
  REACT_APP_AUTH_DOMAIN,
  REACT_APP_DATABASE_URL,
  REACT_APP_PROJECT_ID,
  REACT_APP_STORAGE_BUCKET,
  REACT_APP_MESSAGING_SENDER_ID,
} = process.env

const config = {
  apiKey: REACT_APP_API_KEY,
  authDomain: REACT_APP_AUTH_DOMAIN,
  databaseURL: REACT_APP_DATABASE_URL,
  projectId: REACT_APP_PROJECT_ID,
  storageBucket: REACT_APP_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_MESSAGING_SENDER_ID,
}

const app = !firebase.apps.length
  ? firebase.initializeApp(config)
  : firebase.app()

export const getToken = async () => app.auth().currentUser && app.auth().currentUser.getIdToken(true)

export const fetchWithAuth = async (endpoint, opts = {}) => {
  const token = await getToken()

  let headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
    ...opts.headers,
  }

  if (headers['Content-Type'] === null) {
    headers = R.omit(['Content-Type'], headers)
  }

  const response = await fetch(REACT_APP_REST_ENDPOINT_URL + endpoint, {
    mode: 'cors',
    ...opts,
    headers,
  })

  let responseJson

  try {
    responseJson = await response.json()
  } catch {}

  if (response.status >= 300) {
    let message = (responseJson && responseJson.message) || 'Unknown error'
    if (responseJson && Array.isArray(responseJson.messages)) {
      message = responseJson.messages.join('\n')
    }
    const error = Error(message)
    error.code = response.status
    throw error
  }

  return responseJson
}

export const signIn = async ({ email, password, remember }) => {
  await auth.setPersistence(firebase.auth.Auth.Persistence[remember ? 'LOCAL' : 'NONE'])
  return await auth.signInWithEmailAndPassword(email, password)
}

export const signOut = () => auth.signOut()

export const waitForAuth = () => new Promise(resolve => {
  firebase.auth().onAuthStateChanged(resolve)
})

export const auth = app.auth()

export default app

import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import DialogContentText from '@material-ui/core/DialogContentText'
import Dialog from '../Dialog'
import getStyles from './getStyles'
import AccessIcon from '../AccessIcon'

const renderValue = (value, type, selectValue) => {
  const selectedValue = selectValue ? selectValue(value) : value

  switch (type) {
    case 'checkbox':
      return selectedValue ? 'true' : 'false'
    default:
      return selectedValue
  }
}

const ViewDialog = ({ classes, fields, titleField, data, onClose }) => {
  const access = fields.find(f => f.type === 'access')
  if (access) fields = fields.filter(f => f.type !== 'access')

  return (
    <Dialog
      title={data ? `View ${data[titleField] || ''}` : ''}
      open={!!data}
      onClose={onClose}
    >
      {!!(access && data) && (
        <AccessIcon value={data[access.name]} type="dialog" />
      )}

      <Grid container>
        {data &&
          fields.map(({ name, label, type, selectValue }, i) => (
            <Grid item xs={12} className={classes.row} key={i}>
              <DialogContentText>
                <Fragment>
                  <label className={classes.label}>{label}:</label>
                  {renderValue(data[name], type, selectValue)}
                </Fragment>
              </DialogContentText>
            </Grid>
          ))}
      </Grid>
    </Dialog>
  )
}

export default withStyles(getStyles)(ViewDialog)

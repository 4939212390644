import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import getStyles from './getStyles'

const AccessIcon = memo(({ value = 'legacy', type = 'dialog', classes }) => {
  const tip = (() => {
    switch (value) {
      case 'apple':
        return 'Apple Sign In'
      case 'google':
        return 'Google Sign In'
      case 'legacy':
      default:
        return 'Legacy Sign In'
    }
  })()

  return (
    <img
      className={type === 'dialog' ? classes.dialog : classes.table}
      src={`/assets/images/access-${value}-${
        type === 'dialog' ? '128' : '64'
      }.png`}
      alt={tip}
      title={tip}
    />
  )
})

AccessIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.oneOf(['legacy', 'apple', 'google']),
  type: PropTypes.oneOf(['table', 'dialog']).isRequired,
}

export default withStyles(getStyles)(AccessIcon)

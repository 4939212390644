export default ({ spacing }) => ({
  root: {
    width: '100%',
    marginTop: spacing.unit * 3,
  },
  table: {
    // minWidth: 1020,
    maxWidth: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  actions: {
    textAlign: 'right'
  },
})


import React from 'react'
import { withStyles } from '@material-ui/core'
import { getRegions, getRegionById } from '../../store/selectors'
import CrudPage from '../CrudPage'
import * as models from '../../models'
import getStyles from './getStyles'

const Regions = ({ path }) =>
  <CrudPage {...{
    endpoint: 'api/regions',
    fields: models.region,
    initialData: {
      name: '',
    },
    titleField: 'name',
    title: 'Regions',
    path,
    getItem: getRegionById,
    getItems: getRegions,
  }} />

export default withStyles(getStyles)(Regions)

import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import getStyles from './getStyles'

const Page = ({ classes, title, description, children }) => (
  <Paper className={classes.root} elevation={3}>
    {title && (
      <Typography variant="h5" component="h3">
        {title}
      </Typography>
    )}

    {description && (
      <Typography
        variant="body2"
        color="textSecondary"
        className={classes.description}
      >
        {description}
      </Typography>
    )}

    {children}
  </Paper>
)

Page.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  children: PropTypes.any,
}

export default withStyles(getStyles)(Page)

import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import getStyles from './getStyles'

const Loader = ({ classes }) =>
  <Grid container justify='center' spacing={24}>
    <Grid item>
      <div className={classes.text}>Logging in...</div>
    </Grid>
  </Grid>

export default withStyles(getStyles)(Loader)

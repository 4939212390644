import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles'
import getStyles from './getStyles'

const MediaCard = ({ classes, title, text, imageUrl, actionTitle0, actionUrl0, actionTitle1, actionUrl1,  }) =>
    <Card className={classes.card}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={imageUrl}
          title={title}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {text}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary">
          <a href={actionUrl0}>{actionTitle0}</a>
        </Button>
        <Button size="small" color="primary">
          <a href={actionUrl1}>{actionTitle1}</a>
        </Button>
      </CardActions>
    </Card>

export default withStyles(getStyles)(MediaCard)

export default ({ spacing }) => ({
  root: {
  },
  instructionText: {
    marginTop: spacing.unit * 3,
  },
  target: {
    display: 'flex',
  },
  formControl: {
    margin: spacing.unit * 3,
  },
  group: {
    margin: `${spacing.unit}px 0`,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: `0 ${spacing.unit * 3}px`,
    maxWidth: 320,
  }
})

import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import moment from 'moment'

const VOTING_INTERVAL = 60

const Start = ({ className, match }) => (
  <Grid container className={className} spacing={16}>
    <Grid item xs={12}>
      <Typography variant="body2" gutterBottom>Voting ends at:</Typography>
      <Typography variant="body1">
        {moment(match.finishedAt).add(VOTING_INTERVAL, 'minutes').format('MMMM Do YYYY, h:mm:ss a')}
      </Typography>
    </Grid>
  </Grid>
)

export default Start

export default ({ spacing, palette }) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: spacing.unit * 1,
  },
  loader: {
    margin: spacing.unit * 1,
  },
  component: {
    margin: spacing.unit * 4,
    width: `calc(100% - ${spacing.unit * 8}px)`,
  },
  list: {
    listStyle: 'none',
    padding: 0,
  },
  formControl: {
    display: 'flex',
  },
  addPlayer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  scoringInput: {
    width: '16%',
    minWidth: 20,
    marginBottom: spacing.unit * 2,
  },
  warningText: {
    color: palette.secondary.main,
  },
})

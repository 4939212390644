import { lighten } from '@material-ui/core/styles/colorManipulator'

export default ({ palette, spacing }) => ({
  root: {
    paddingRight: spacing.unit,
  },
  highlight:
    palette.type === 'light'
      ? {
        color: palette.secondary.main,
        backgroundColor: lighten(palette.secondary.light, 0.85),
      }
      : {
        color: palette.text.primary,
        backgroundColor: palette.secondary.dark,
      },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
})

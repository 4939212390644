import {
  getTeamsForSelect,
  getVenuesForSelect,
  getFormFieldsForSelect,
  getRegionsForSelect,
  getFreeRegionsForSelect,
  getFreeVenuesForSelect,
  getLanguagesForSelect,
} from './store/selectors'

const roles = ['', 'admin', 'captain', 'player'].map(id => ({
  value: id,
  label: id,
}))

const freeAccountsRoles = ['admin', 'user'].map(id => ({
  value: id,
  label: id,
}))

// TODO move to component
const ui = { component: 'th', padding: 'none', scope: 'row' }

const getEmergencyContacts = fields => {
  if (fields) {
    var resultValues = fields
      .filter(item => item.form === 'hs_contact')
      .map(contactItem => contactItem.value)
    if (resultValues) return resultValues.join(' ')
  }
}

const getTeamsNames = teams => {
  if (teams) return teams.map(team => team.name).join(', ')
}

const statusEmojiMapTeam = {
  setup: '👨‍✈️',
  ready: '✅',
  started: '🥎',
  finished: '🏁',
}

const getStatusWithEmojiForTeam = status =>
  status && `${statusEmojiMapTeam[status]} ${status.toUpperCase()}`

const statusEmojiMapMatch = {
  setup: '☑️',
  ready: '✅',
  started: '🥎',
  finished: '🏁',
}

const getStatusWithEmojiForMatch = status =>
  status && `${statusEmojiMapMatch[status]} ${status.toUpperCase()}`

const statusTooltipForTeamMap = {
  setup: 'Waiting for captain to submit batting order on the app',
  ready: 'Batting order submitted, waiting for captain to press Start Match',
  started: 'Captain pressed Start Match',
  finished: 'Captain pressed End Match',
}

const getStatusTooltipForTeam = status =>
  status && `${statusTooltipForTeamMap[status]}`

export const account = [
  { name: 'email', label: 'Email', ui },
  { name: 'firstName', label: 'First name' },
  { name: 'lastName', label: 'Last name' },
  { name: 'role', label: 'Role', type: 'select', values: roles },
  { name: 'teamId', label: 'Team', type: 'select', values: getTeamsForSelect },
  { name: 'mvp', label: 'MVP', type: 'checkbox' },
  {
    name: 'fields',
    label: 'Emergency Contact',
    selectValue: getEmergencyContacts,
    disabled: true,
  },
]

export const freeAccount = [
  { name: 'email', label: 'Email', ui },
  { name: 'firstName', label: 'First name' },
  { name: 'lastName', label: 'Last name' },
  {
    name: 'registrationMode',
    label: 'Access',
    type: 'access',
  },
  { name: 'role', label: 'Role', type: 'select', values: freeAccountsRoles },
  { name: 'teams', label: 'Teams', selectValue: getTeamsNames, disabled: true },
  {
    name: 'fields',
    label: 'Emergency Contact',
    selectValue: getEmergencyContacts,
    disabled: true,
  },
  { name: 'isBlocked', label: 'Blocked', type: 'checkbox' },
]

export const invitation = [
  { name: 'email', label: 'Email', id: true, disabled: true, ui },
  { name: 'firstName', label: 'First name' },
  { name: 'lastName', label: 'Last name' },
  { name: 'role', label: 'Role', type: 'select', values: roles },
  { name: 'teamId', label: 'Team', type: 'select', values: getTeamsForSelect },
  { name: 'emailSent', label: 'Email sent', type: 'checkbox' },
  { name: 'accepted', label: 'Accepted', type: 'checkbox' },
]

export const team = [
  { name: 'name', label: 'Name', ui },
  {
    name: 'regionId',
    label: 'Region',
    type: 'select',
    values: getRegionsForSelect,
  },
  { name: 'imageUrl', label: 'Image', type: 'image' },
]

export const freeTeam = [
  { name: 'name', label: 'Name', ui },
  { name: 'imageUrl', label: 'Image URL', type: 'image' },
  {
    name: 'regionId',
    label: 'Region',
    type: 'select',
    values: getFreeRegionsForSelect,
  },
]

export const rules = [
  { name: 'name', label: 'Name', ui },
  { name: 'url', label: 'URL' },
  {
    name: 'lang',
    label: 'Language',
    type: 'native-select',
    values: getLanguagesForSelect,
  },
  { name: 'order', label: 'Order', type: 'number' },
]

export const match = [
  {
    name: 'homeTeamId',
    label: 'Home Team',
    type: 'select',
    values: getTeamsForSelect,
  },
  {
    name: 'homeStatus',
    label: 'Status',
    tooltip: getStatusTooltipForTeam,
    disabled: true,
    selectValue: getStatusWithEmojiForTeam,
  },
  {
    name: 'awayTeamId',
    label: 'Away Team',
    type: 'select',
    values: getTeamsForSelect,
  },
  {
    name: 'awayStatus',
    label: 'Status',
    tooltip: getStatusTooltipForTeam,
    disabled: true,
    selectValue: getStatusWithEmojiForTeam,
  },
  {
    name: 'venueId',
    label: 'Venue',
    type: 'select',
    values: getVenuesForSelect,
  },
  { name: 'date', label: 'Date', type: 'datetime', format: 'YYYY/MM/DD HH:mm' },
  { name: 'final', label: 'Final', type: 'checkbox' },
  { name: 'notificationSent', label: 'Notification sent', type: 'checkbox' },
  {
    name: 'status',
    label: 'Status',
    disabled: true,
    selectValue: getStatusWithEmojiForMatch,
  },
]

export const venue = [
  { name: 'name', label: 'Name', ui },
  { name: 'address', label: 'Address' },
  { name: 'lat', label: 'Latitude' },
  { name: 'lon', label: 'Longitude' },
]

export const freeVenue = [
  { name: 'name', label: 'Name', ui },
  { name: 'address', label: 'Address' },
  { name: 'latitude', label: 'Latitude', type: 'number' },
  { name: 'longitude', label: 'Longitude', type: 'number' },
  {
    name: 'regionId',
    label: 'Region',
    type: 'select',
    values: getFreeRegionsForSelect,
  },
]

export const freeSeries = [
  { name: 'name', label: 'Name', ui },
  {
    name: 'venueId',
    label: 'Venue',
    type: 'select',
    values: getFreeVenuesForSelect,
  },
  {
    name: 'regionId',
    label: 'Region',
    type: 'select',
    values: getFreeRegionsForSelect,
  },
  { name: 'maxTeamCount', label: 'Maximum Team Count', type: 'number' },
  { name: 'isPublic', label: 'Public', type: 'checkbox' },
]

export const region = [
  { name: 'name', label: 'Name', ui },
  { name: 'order', label: 'Order' },
  { name: 'imageUrl', label: 'Image URL' },
]

export const freeRegion = [
  { name: 'name', label: 'Name', ui },
  { name: 'order', label: 'Order', type: 'number' },
  { name: 'imageUrl', label: 'Image URL' },
]

export const reward = [
  { name: 'name', label: 'Name', ui },
  { name: 'lead', label: 'Lead text' },
  { name: 'coupon', label: 'Coupon' },
  { name: 'url', label: 'URL' },
]

const forms = [
  { value: 'hs_conditions', label: 'Healt & Safety - Conditions' },
  { value: 'hs_contact', label: 'Healt & Safety - Contact' },
]

const formFieldTypes = ['text', 'checkbox'].map(value => ({
  label: value,
  value,
}))

export const formFields = [
  { name: 'id', label: 'Id', ui },
  { name: 'label', label: 'Label' },
  { name: 'type', label: 'Type', type: 'select', values: formFieldTypes },
  { name: 'order', label: 'Order' },
  {
    name: 'requirement',
    label: 'Requirement',
    type: 'select',
    values: getFormFieldsForSelect,
  },
  { name: 'mandatory', label: 'Mandatory', type: 'checkbox' },
  { name: 'form', label: 'Form group', type: 'select', values: forms },
]

export const news = [
  { name: 'title', label: 'Title', ui },
  { name: 'date', label: 'Date', type: 'datetime', format: 'YYYY/MM/DD HH:mm' },
  { name: 'contentUrl', label: 'Content URL' },
  { name: 'imageUrl', label: 'Image URL' },
]

import React from 'react'
import { withStyles } from '@material-ui/core'
import { getFormFields, getFormFieldById } from '../../store/selectors'
import CrudPage from '../CrudPage'
import * as models from '../../models'
import getStyles from './getStyles'

const FormFields = ({ path }) =>
  <CrudPage {...{
    endpoint: 'api/form-fields',
    fields: models.formFields,
    initialData: {
      id: '',
      label: '',
      type: 'checkbox',
      order: 1,
      requirement: '',
      mandatory: false,
      form: '',
    },
    titleField: 'label',
    title: 'Form fields',
    path,
    getItem: getFormFieldById,
    getItems: getFormFields,
  }} />

export default withStyles(getStyles)(FormFields)

import React, { Fragment, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import { actions } from '../../store'
import SpinButton from '../SpinButton'
import { Context } from './SimulateMatchDialog'

const Team = ({ match, side }) => {
  const teamId = match[`${side}TeamId`]
  const teamName = match[`${side}Team`].name
  const status = match[`${side}Status`]
  const finished = status === 'finished'
  const { loading } = useContext(Context)

  const handleToggle = async () => {
    const data = {
      matchId: match.id,
      teamId,
      payload: { finish: !finished },
    }

    await actions.simulateMatch('finish', data)
  }

  return (
    <Fragment>
      <Typography variant="body2" gutterBottom>{teamName}</Typography>
      <SpinButton
        size="small"
        color="primary"
        variant="contained"
        disabled={loading}
        loading={loading}
        onClick={handleToggle}
      >
        {finished ? 'Un-finish' : 'Finish'}
      </SpinButton>
    </Fragment>
  )
}

const Finish = ({ className, match }) => (
  <Grid container className={className} spacing={16}>
    <Grid item xs={12} md={6}>
      <Team side='home' match={match} />
    </Grid>
    <Grid item xs={12} md={6}>
      <Team side='away' match={match} />
    </Grid>
  </Grid>
)

export default Finish

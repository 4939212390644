export default () => ({
  list: {
    width: 'auto',
  },
  nested: {
    paddingLeft: '30px',
  },
  nestedDivider: {
    marginLeft: '25px',
  },
})

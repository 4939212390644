import { compose, map, path, prop, either, not, filter, whereEq } from 'ramda'
import moment from 'moment'
import findItemBy from '../lib/findItemBy'
import languages from './languages'

const formatDate = date => moment(date).format('dddd, MMMM Do')

export const getError = path(['error'])
export const getErrorMessage = path(['error', 'message'])
export const getUser = path(['user'])
export const getSnackMessage = path(['snackMessage'])
export const isInitialized = path(['initialized'])
export const isLoading = either(path(['loading']), compose(not, isInitialized))

export const getFreeVenues = path(['venues'])
export const getFreeVenueById = (state, { id }) =>
  findItemBy(getFreeVenues, { id })(state)
export const getFreeRegions = path(['regions'])
export const getFreeRegionById = (state, { id }) =>
  findItemBy(getFreeRegions, { id })(state)
export const getFreeTeams = path(['teams'])
export const getFreeTeamById = (state, { id }) =>
  findItemBy(getFreeTeams, { id })(state)
export const getFreeSeries = path(['series'])
export const getFreeSeriesById = (state, { id }) =>
  findItemBy(getFreeSeries, { id })(state)
export const getFreeAccounts = path(['accounts'])
export const getFreeAccountById = (state, { id }) =>
  findItemBy(getFreeAccounts, { id })(state)

export const getRules = path(['rules'])
export const getRulesById = (state, { id }) =>
  findItemBy(getRules, { id })(state)

export const getInvitations = path(['api/invitations'])
export const getAccounts = path(['api/accounts'])
export const getTeams = path(['api/teams'])
export const getMatches = path(['api/matches'])
export const getPositions = path(['api/positions'])
export const getVenues = path(['api/venues'])
export const getRegions = path(['api/regions'])
export const getRewards = path(['api/rewards'])
export const getFormFields = path(['api/formFields'])
export const getNews = path(['api/news'])
export const getAccountById = (state, { id }) =>
  findItemBy(getAccounts, { id })(state)
export const findAccounts = (state, filterObj) =>
  compose(filter(whereEq(filterObj)), getAccounts)(state)
export const getInvitationByEmail = (state, { email }) =>
  findItemBy(getInvitations, { email })(state)
export const getTeamById = (state, { id }) =>
  findItemBy(getTeams, { id })(state)
export const getMatchById = (state, { id }) =>
  findItemBy(getMatches, { id })(state)
export const getVenueById = (state, { id }) =>
  findItemBy(getVenues, { id })(state)
export const getRegionById = (state, { id }) =>
  findItemBy(getRegions, { id })(state)
export const getRewardById = (state, { id }) =>
  findItemBy(getRewards, { id })(state)
export const getFormFieldById = (state, { id }) =>
  findItemBy(getFormFields, { id })(state)
export const getNewsById = (state, { id }) => findItemBy(getNews, { id })(state)

// for UI <select>
const createSelectSelector = (selectValue, selectLabel, selector) =>
  compose(
    map(object => ({
      value: selectValue(object),
      label: selectLabel(object),
    })),
    selector
  )

export const getAccountsForSelect = createSelectSelector(
  prop('id'),
  prop('email'),
  getAccounts
)
export const getMatchesForSelect = createSelectSelector(
  prop('id'),
  ({ homeTeam, awayTeam, date }) =>
    `${homeTeam.name} - ${awayTeam.name} // ${formatDate(date)}`,
  getMatches
)
export const getTeamsForSelect = createSelectSelector(
  prop('id'),
  prop('name'),
  getTeams
)
export const getVenuesForSelect = createSelectSelector(
  prop('id'),
  prop('name'),
  getVenues
)
export const getFormFieldsForSelect = createSelectSelector(
  prop('id'),
  prop('label'),
  getFormFields
)
export const getRegionsForSelect = createSelectSelector(
  prop('id'),
  prop('name'),
  getRegions
)
export const getPositionsForSelect = createSelectSelector(
  prop('id'),
  prop('displayName'),
  getPositions
)

export const getFreeRegionsForSelect = createSelectSelector(
  prop('id'),
  prop('name'),
  getFreeRegions
)
export const getFreeVenuesForSelect = createSelectSelector(
  prop('id'),
  prop('name'),
  getFreeVenues
)
export const getLanguagesForSelect = createSelectSelector(
  prop('code'),
  prop('name'),
  () => languages
)

import React from 'react'
import { withStyles } from '@material-ui/core'
import { getRules, getRulesById } from '../../store/selectors'
import CrudPage from '../CrudPage'
import * as models from '../../models'
import getStyles from './getStyles'

const Rules = ({ path }) => (
  <CrudPage
    {...{
      endpoint: 'rules',
      fields: models.rules,
      titleField: 'name',
      title: 'Rules',
      initialData: {
        name: '',
        url: '',
        lang: 'en',
        order: '',
      },
      path,
      getItem: getRulesById,
      getItems: getRules,
    }}
  />
)

export default withStyles(getStyles)(Rules)

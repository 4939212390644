import React from 'react'
import { Link } from '@reach/router'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'

export default ({ className, children, title, to, icon: Icon }) =>
  <Tooltip title={title} leaveDelay={50}>
    <Fab className={className} color='secondary' size='small' to={to} component={Link}>
      {children || <Icon />}
    </Fab>
  </Tooltip>

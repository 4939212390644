import React, { Fragment, useState, useContext, useReducer } from 'react'
import { compose, path, filter, whereEq, pluck } from 'ramda'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import BaseSelect from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { actions, connect } from '../../store'
import { findAccounts, getPositionsForSelect } from '../../store/selectors'
import SpinButton from '../SpinButton'
import { Context } from './SimulateMatchDialog'

const playersReducer = (players, { type, payload }) => {
  switch (type) {
    case 'ADD_PLAYER':
      return [...players, payload]
    case 'REMOVE_PLAYER':
      return players.filter(({ id }) => id !== payload.id)
    default:
      return
  }
}

const getAccountOption = ({ id, firstName, lastName }) => ({
  value: id,
  label: `${firstName} ${lastName}`,
})

const createPlayer = ({ account, positionId }) => ({
  ...account,
  positionId,
})

const mapStateToProps = (state, { side, match }) => {
  const teamId = match[`${side}TeamId`]
  const status = match[`${side}Status`]

  return {
    accounts: findAccounts(state, { teamId }),
    positions: getPositionsForSelect(state),
    teamId,
    status,
  }
}

const Team = connect(mapStateToProps)(({ accounts, match, teamId, side, positions }) => {
  const findAccount = id => compose(
    path([0]),
    filter(whereEq({ id }))
  )(accounts)

  const initialPlayers = match.players
    .filter(player => player.teamId === teamId)
    .map(({ id, positionId }) => createPlayer({
      account: findAccount(id),
      positionId,
    }))

  const teamName = match[`${side}Team`].name
  const { classes, loading } = useContext(Context)
  const [dirty, setDirty] = useState(false)
  const [playerId, setPlayerId] = useState()
  const [positionId, setPositionId] = useState()
  const [players, dispatchPlayers] = useReducer(playersReducer, initialPlayers)

  const playerIds = pluck('id', players)
  const accountOptions = accounts
    .filter(({ id }) => !playerIds.includes(id))
    .map(getAccountOption)

  const addPlayer = payload => dispatchPlayers({
    type: 'ADD_PLAYER',
    payload,
  })

  const removePlayer = payload => dispatchPlayers({
    type: 'REMOVE_PLAYER',
    payload,
  })

  const handleAdd = () => {
    const account = findAccount(playerId)

    addPlayer(createPlayer({ account, positionId }))
    setPlayerId('')
    setPositionId('')
    setDirty(true)
  }

  const handleRemove = id => {
    removePlayer({ id })
    setDirty(true)
  }

  const handleSave = async () => {
    const data = {
      matchId: match.id,
      teamId,
      payload: players.map(({ id, positionId }, order) => ({
        playerId: id,
        positionId,
        order,
      })),
    }

    await actions.simulateMatch('setup', data)
    setDirty(false)
  }

  const disabled = loading || !playerId || !positionId

  const Select = ({ name, label, value, options, onChange }) => (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor={'add-player-' + name}>{label}</InputLabel>
      <BaseSelect
        value={value || ''}
        onChange={({ target }) => onChange(target.value)}
        inputProps={{
          id: 'add-player-' + name,
        }}
        margin='none'
        autoWidth
      >
        {options.map(({ value, label }, i) =>
          <MenuItem key={i} value={value}>{label}</MenuItem>
        )}
      </BaseSelect>
    </FormControl>
  )

  return (
    <Fragment>
      <Typography variant="body2" gutterBottom>{teamName}</Typography>

      <Grid container spacing={8}>
        <Grid item xs={12} sm={4}>
          <Select
            name='account'
            label='Player'
            value={playerId}
            options={accountOptions}
            onChange={setPlayerId}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Select
            name='position'
            label='Position'
            value={positionId}
            options={positions}
            onChange={setPositionId}
          />
        </Grid>
        <Grid item xs={12} sm={4} className={classes.addPlayer}>
          <Button
            size="small"
            color="primary"
            disabled={disabled}
            onClick={handleAdd}
          >
            Add player
          </Button>
        </Grid>
      </Grid>

      <ul className={classes.list}>
        {players.map(({ id, firstName, lastName, positionId }) =>
          <li key={id}>
            {firstName} {lastName} ({positionId})
            <Button size="small" color="secondary" onClick={() => handleRemove(id)}>
              remove
            </Button>
          </li>
        )}
      </ul>

      {dirty && <Typography gutterBottom className={classes.warningText}>
        Please press 'save' to submit player setup.
      </Typography>}

      <SpinButton
        size="small"
        color="primary"
        variant="contained"
        disabled={loading || !dirty}
        loading={loading}
        onClick={handleSave}
      >
        save
      </SpinButton>
    </Fragment>
  )
})

const Setup = ({ className, match }) => (
  <Grid container className={className} spacing={16}>
    <Grid item xs={12} md={6}>
      <Team side='home' match={match} />
    </Grid>
    <Grid item xs={12} md={6}>
      <Team side='away' match={match} />
    </Grid>
  </Grid>
)

export default Setup

export default () => ({
  root: {
  },
  row: {
    margin: '0.6em 0',
  },
  formControl: {
    display: 'flex',
  },
})
